import Alert from "assets/Alert";
import Checkbox from "../Checkbox";

function Select({
  checkboxID,
  id,
  label,
  secondLabel,
  required,
  children,
  classes = "",
  classesSelect = "",
  classesCheckbox = "",
  disabled = false,
  alert = false,
  error = false,
  onChange,
  onClick,
  value,
  style,
  handleClickCheckbox,
  checkedValues,
}: any) {
  const checkClassName = () => {
    if (alert) {
      return "bg-white border text-gray-500 outline-none border-yellow-400";
    } else if (disabled && error) {
      return "bg-gray-200 border text-slate-400 cursor-not-allowed outline-none border-red-500";
    } else if (disabled && !error) {
      return "bg-gray-200 border text-slate-400 cursor-not-allowed outline-primary-purple";
    } else if (!disabled && error) {
      return "bg-white border text-gray-500 outline-none border-red-500";
    } else if (!disabled && !error) {
      return "bg-white border border-gray-400 text-gray-500 outline-primary-purple";
    }
  };

  return (
    <div className={classes}>
      <div className="flex justify-between">
        {label && (
          <div className="flex">
            <label
              htmlFor={id || value}
              className="block text-sm font-medium text-gray-400"
            >
              {label} {required && <span className="text-red-500">*</span>}
            </label>
            {secondLabel && secondLabel}
          </div>
        )}
        {alert && (
          <div className="flex">
            <Alert classes="h-4 w-4 mr-2" />
            <Checkbox
              className={classesCheckbox}
              id={checkboxID || label.replace(/\s/g, "")}
              key={checkboxID || label.replace(/\s/g, "")}
              type="checkbox"
              name={checkboxID || label.replace(/\s/g, "")}
              onChange={(e: any) => handleClickCheckbox(e)}
              isChecked={checkedValues?.includes(
                checkboxID || label.replace(/\s/g, "")
              )}
            />
          </div>
        )}
      </div>
      <select
        name={value}
        id={id || value}
        className={`cursor-pointer text-sm rounded-md block w-full font-medium ${classesSelect} 
        ${checkClassName()}`}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
        value={value}
        style={style}
      >
        {children}
      </select>
    </div>
  );
}

export default Select;
