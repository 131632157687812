import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {Dialog} from "@headlessui/react";

// Components
import Title from "components/Common/Title";
import Subtitle from "components/Common/Subtitle";
import Button from "components/Common/Button";
import Modal from "components/Modal";

// Pages
import Prize from "./TypePrices/Prize";
import Offer from "./TypePrices/Offer";
import GrandPrize from "./TypePrices/GrandPrize";
import MerchantInfo from "./MerchantInfo";

// Redux
import {useSelector} from "react-redux";
import {RootState} from "store/store";

// Assets
import {ArrowBack} from "assets/Arrows";
import PrizeIcon from "assets/Prize";
import Reward from "./TypePrices/Reward";
import Spinner from "components/Common/Spinner";

function AddNewItems() {
  const modal = useSelector((state: RootState) => state.modal);
  const location = useLocation();
  const navigate = useNavigate();
  const [merchantID, setMerchantID] = useState(null);
  const [merchantRewards, setMerchantRewards] = useState([]);
  const [blockerGP, setBlockerGP] = useState(true);
  const [blockerReward, setBlockerReward] = useState(true);
  const [sendForm, setSendForm] = useState(false);
  const [checked, setChecked] = useState(location?.state?.type || "prize");

  const handleSelectGrandPrize = () => {
    setChecked("grandprize");
    setSendForm(false);
  };
  const handleSelectReward = () => {
    setChecked("reward");
    setSendForm(false);
  };

  useEffect(() => {
    if (blockerGP === false && checked === "grandprize") {
      if (!location?.state?.type) {
        setChecked("prize");
        return;
      }
      if (location?.state?.type === "grandprize" && !blockerGP) {
        setChecked("prize");
        return;
      }
    }
  }, [blockerGP]);

  useEffect(() => {
    if (blockerReward === false && checked === "reward") {
      if (!location?.state?.type) {
        setChecked("prize");
        return;
      }
      if (location?.state?.type === "reward" && !blockerReward) {
        setChecked("prize");
        return;
      }
    }
  }, [blockerReward]);

  return (
    <section className="mt-3">
      <div className="mb-5">
        <Link
          to={".."}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="text-sm text-gray-400 flex hover:text-primary-purple max-w-max"
        >
          <ArrowBack classes="h-5 w-5 mr-2" />
          Back to item list
        </Link>
      </div>
      <div className="flex justify-between">
        <Title classes="flex items-center">Create Item</Title>
        <Button
          classes="mr-16"
          variant={sendForm ? "disabled" : "add"}
          onClick={() => setSendForm(true)}
        >
          {sendForm ? (
            <Spinner classes="w-5 h-5 border-b-gray-500" />
          ) : (
            <PrizeIcon />
          )}
          <p className="ml-2">Create new item</p>
        </Button>
      </div>
      <div className="flex mt-5 flex-wrap">
        <MerchantInfo
          idMerchant={location?.state?.id ? location?.state?.id : null}
          setMerchantID={setMerchantID}
          setMerchantRewards={setMerchantRewards}
          setBlockerGP={setBlockerGP}
          setBlockerReward={setBlockerReward}
        />
        <div className="bg-white p-5 pr-0 prize-info-merchant">
          <form>
            <Subtitle classes="mt-1 mb-5">Create item</Subtitle>
            <hr />
            <div className="flex w-100">
              <div className="mt-5 w-full">
                <p className="text-sm font-semibold mb-3">Type of item: </p>
                <div>
                  <div className="flex">
                    {/* First Radio Button */}
                    <div className="flex items-center mr-4 cursor-pointer">
                      <div
                        onClick={() => {
                          setChecked("prize");
                          setSendForm(false);
                        }}
                        className="w-4 h-4 border-2 rounded-full border-primary-purple flex justify-center items-center mr-2"
                      >
                        {checked === "prize" && (
                          <div
                            id="prize"
                            className="w-3 h-3 border-2 rounded-full bg-primary-purple"
                          ></div>
                        )}
                      </div>
                      <label
                        onClick={() => {
                          setChecked("prize");
                          setSendForm(false);
                        }}
                        className="cursor-pointer"
                        htmlFor="prize"
                      >
                        Prize
                      </label>
                    </div>
                    {/* Second Radio Button */}
                    <div className="flex items-center mr-4 cursor-pointer">
                      <div
                        onClick={() => {
                          setChecked("offer");
                          setSendForm(false);
                        }}
                        className="w-4 h-4 border-2 rounded-full border-primary-purple flex justify-center items-center mr-2"
                      >
                        {checked === "offer" && (
                          <div
                            id="offer"
                            className="w-3 h-3 border-2 rounded-full bg-primary-purple"
                          ></div>
                        )}
                      </div>
                      <label
                        onClick={() => {
                          setChecked("offer");
                          setSendForm(false);
                        }}
                        htmlFor="offer"
                        className="cursor-pointer"
                      >
                        Offer
                      </label>
                    </div>

                    {/* Thirth Radio Button */}
                    <div className="flex items-center cursor-pointer">
                      <div
                        onClick={() =>
                          blockerGP === true
                            ? handleSelectGrandPrize()
                            : toast.info(
                                "Merchant not allowed to create Grand Prize"
                              )
                        }
                        className={
                          blockerGP === true
                            ? "w-4 h-4 border-2 rounded-full border-primary-purple flex justify-center items-center mr-2"
                            : "w-4 h-4 border-2 rounded-full border-gray-300 bg-gray-300 mr-2"
                        }
                      >
                        {checked === "grandprize" && (
                          <div
                            id="grandprize"
                            className="w-3 h-3 border-2 rounded-full bg-primary-purple"
                          ></div>
                        )}
                      </div>
                      <label
                        onClick={() =>
                          blockerGP === true
                            ? handleSelectGrandPrize()
                            : toast.info(
                                "Merchant not allowed to create Reward"
                              )
                        }
                        htmlFor="grandprize"
                        className={`${
                          blockerGP === false && "text-gray-300"
                        } cursor-pointer`}
                      >
                        Grand Prize
                      </label>
                    </div>

                    {/* Fourth Radio Button */}
                    <div className="flex items-center ml-4 cursor-pointer">
                      <div
                        onClick={() =>
                          blockerReward === true
                            ? handleSelectReward()
                            : toast.info(
                                "Merchant not allowed to create Reward"
                              )
                        }
                        className={
                          blockerReward === true
                            ? "w-4 h-4 border-2 rounded-full border-primary-purple flex justify-center items-center mr-2"
                            : "w-4 h-4 border-2 rounded-full border-gray-300 bg-gray-300 mr-2"
                        }
                      >
                        {checked === "reward" && (
                          <div
                            id="reward"
                            className="w-3 h-3 border-2 rounded-full bg-primary-purple"
                          ></div>
                        )}
                      </div>
                      <label
                        onClick={() =>
                          blockerReward === true
                            ? handleSelectReward()
                            : toast.info(
                                "Merchant not allowed to create Reward"
                              )
                        }
                        htmlFor="reward"
                        className={`${
                          blockerReward === false && "text-gray-300"
                        } cursor-pointer`}
                      >
                        Reward
                      </label>
                    </div>
                  </div>
                </div>
                {checked === "prize" && (
                  <Prize
                    sendForm={sendForm}
                    setSendForm={setSendForm}
                    checked={checked}
                    merchantID={merchantID || null}
                  />
                )}
                {checked === "offer" && (
                  <Offer
                    sendForm={sendForm}
                    setSendForm={setSendForm}
                    checked={checked}
                    merchantID={merchantID || null}
                  />
                )}
                {checked === "grandprize" && (
                  <GrandPrize
                    sendForm={sendForm}
                    setSendForm={setSendForm}
                    checked={checked}
                    merchantID={merchantID || null}
                  />
                )}
                {checked === "reward" && (
                  <Reward
                    sendForm={sendForm}
                    setSendForm={setSendForm}
                    checked={checked}
                    merchantID={merchantID || null}
                    merchantRewards={merchantRewards}
                    loyaltyID={location?.state?.loyaltyId || null}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      {modal.type === "showImage" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded items-center">
            <Dialog.Overlay />
            <img
              src={modal?.imageModal}
              alt={`modal-${modal?.imageModal}`}
              className="image-modal-height"
            />
          </div>
        </Modal>
      )}
    </section>
  );
}

export default AddNewItems;
