import {ArrowNext} from "assets/Arrows";
import Delete from "assets/Delete";
import Search from "assets/Search";
import Upload from "assets/Upload";
import Button from "components/Common/Button";
import DropdownCategory from "components/Common/DropdownCategory";
import Input from "components/Common/Input";
import DatePicker from "components/Common/Input/DatePicker";
import Dropzone from "components/Common/Input/Dropzone";
import Select from "components/Common/Input/Select";
import Subtitle from "components/Common/Subtitle";
import Textarea from "components/Common/Textarea";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Tooltip} from "react-tooltip";
import {useGetCategoriesTreeQuery} from "store/api/categoriesApi";
import {
  useCreateRewardMutation,
  useGetLoyaltyProgramQuery,
  useLazyGetIndividualLoyaltyProgramQuery,
  useUpdateImageItemMutation,
} from "store/api/itemApi";
import {setLoyaltyProgram} from "store/filters/filtersSlice";
import {RootState} from "store/store";
import {setSubMerchantTabIndex} from "store/tabs/tabsSlice";

// Utils
import routes from "utils/routesByRole";
import {findItemById, getFilteredNames} from "utils/workingHoursUtils";
import {addDays, getNextDayEndDate} from "utils/addDays";
import {getTodayDate} from "utils/getTodayDate";
interface IOffer {
  sendForm: boolean;
  setSendForm: Function;
  checked: string;
  merchantID: string | number | null;
  merchantRewards: any;
  loyaltyID: string | null;
}

type RewardData = {
  loyalityProgram: string;
  rewards: {
    rewardOrder: number;
    id: string;
    rewardName: string;
    rewardDesc: string;
    expiredAt: string;
    stampNeeded: string;
    minAgeReq: string;
    rewardPhoto: string;
    rewardFormData: any;
    categories: {id: number}[];
    dropdown: boolean;
    elementChecked: string[];
    elementNames: string[];
    imageCropModalType: string;
  }[];
};

type DropdownSearchFilter = {
  reward1: string;
  reward2: string;
  reward3: string;
};

function Reward({
  sendForm,
  setSendForm,
  checked,
  merchantID,
  merchantRewards,
  loyaltyID,
}: IOffer) {
  const user = useSelector((state: RootState) => state.user.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rewardData, setRewardData] = useState<RewardData>({
    loyalityProgram: loyaltyID ? loyaltyID.toString() : "",
    rewards: [],
  });

  const [leftRewardsAvaialble, setLeftRewardsAvaialble] = useState(0);
  const [loyaltyProgramLocal, setLoyaltyProgramLocal] = useState<any>(null);
  const [isExpired, setIsExpired] = useState<boolean>(false);

  const [params] = useState("?limit=1000");

  const [expiredDateOriginal, setExpiredDateOriginal] = useState("");

  const [isInputDisabled] = useState(() => {
    //  @ts-ignore
    return routes[user.data.role].urlAllowed?.merchant?.includes("add")
      ? false
      : true;
  });

  const [inputError, setInputError] = useState(false);
  const [dropdownSearchFilter, setDowpdownSearchFilter] =
    useState<DropdownSearchFilter>({
      reward1: "",
      reward2: "",
      reward3: "",
    });

  const {
    data: dataCategories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    error,
    // @ts-ignore
  } = useGetCategoriesTreeQuery();

  const {data: dataLoyaltyProgram, isLoading: isLoadingLoyaltyProgram} =
    useGetLoyaltyProgramQuery({id: merchantID, params});

  const [loyaltyProgramData] = useLazyGetIndividualLoyaltyProgramQuery();

  const [updateImageItem] = useUpdateImageItemMutation();

  const [createRewardMutation] = useCreateRewardMutation();

  useEffect(() => {
    if (rewardData?.loyalityProgram) {
      loyaltyProgramData({idLoyalty: rewardData?.loyalityProgram}).then(
        (res) => {
          if (res) {
            const expDate = res?.data?.endDate;
            setExpiredDateOriginal(expDate);
          }
        }
      );
    }
  }, [rewardData.loyalityProgram]);

  useEffect(() => {
    if (loyaltyID) {
      handleCalculateLeftRewards(parseInt(loyaltyID));
    }
  }, [loyaltyID, dataLoyaltyProgram]);

  // Send form
  useEffect(() => {
    if (sendForm && checked === "reward") {
      handleSubmitReward();
    }
  }, [sendForm, checked]);

  const getTreeCategory = (elementsChecked: any) => {
    return elementsChecked.map((id: any, index: number) => {
      let rootParentElement;

      const currentElement = findItemById(dataCategories, id.toString());

      if (!currentElement?.parentId) {
        return {
          id: elementsChecked[index],
          root: currentElement.name,
          rootId: currentElement.id,
        };
      }

      const parentElement = findItemById(
        dataCategories,
        currentElement.parentId.toString()
      );

      if (parentElement?.parentId) {
        rootParentElement = findItemById(
          dataCategories,
          parentElement.parentId.toString()
        );
      }

      // Save the root parent element and the parent element nested
      if (rootParentElement) {
        return {
          id: elementsChecked[index],
          root: rootParentElement.name,
          rootId: rootParentElement.id,
          parent: parentElement.name,
          parentId: parentElement.id,
          child: currentElement.name,
          childId: currentElement.id,
        };
      } else {
        return {
          id: elementsChecked[index],
          parent: parentElement.name,
          parentId: parentElement.id,
          child: currentElement.name,
          childId: currentElement.id,
        };
      }
    });
  };

  const handleClickCheckboxForReward = (rewardIndex: number, e: any) => {
    const {id, name, checked} = e.target;

    setRewardData((prevData) => {
      const updatedRewards = prevData.rewards.map((reward, index) => {
        if (index === rewardIndex) {
          const updatedElementChecked = reward.elementChecked.includes("all")
            ? [id]
            : [...reward.elementChecked, id];

          const updatedElementNames = reward.elementChecked.includes("all")
            ? [name]
            : [...reward.elementNames, name];

          if (!checked) {
            const filteredChecked = reward.elementChecked.filter(
              (item: any) => item !== id.toString()
            );
            const filteredNames = reward.elementNames.filter(
              (item: any) => item !== name.toLowerCase()
            );

            return {
              ...reward,
              elementChecked: filteredChecked,
              elementNames: filteredNames,
            };
          }

          return {
            ...reward,
            elementChecked: updatedElementChecked,
            elementNames: updatedElementNames,
          };
        }
        return reward;
      });

      return {...prevData, rewards: updatedRewards};
    });
  };

  const handleClickDropdown = (e: any, type: string, rewardIndex: number) => {
    e.preventDefault();
    setRewardData((prevData) => {
      const updatedRewards = prevData.rewards.map((reward, index) => {
        if (index === rewardIndex) {
          if (type !== "save") {
            // Si el tipo no es "save", reiniciar elementChecked y elementNames a un arreglo vacío
            return {
              ...reward,
              dropdown: !reward.dropdown,
              elementChecked: [],
              elementNames: [],
            };
          } else {
            // Si el tipo es "save", solo cambiar el valor de dropdown
            return {
              ...reward,
              dropdown: !reward.dropdown,
            };
          }
        }
        return reward;
      });

      return {...prevData, rewards: updatedRewards};
    });
  };

  const updateLoyalityData = (value: any, id: string, target: string) => {
    setRewardData({
      ...rewardData,
      rewards: rewardData.rewards.map((item) => {
        if (item.id === id) {
          return {...item, [target]: value};
        }
        return item;
      }),
    });
  };

  const updateLoyalityImage = (
    value: any,
    id: string,
    target: string,
    formData = null
  ) => {
    setRewardData((prev) => {
      const updatedRewards = prev.rewards.map((item) => {
        if (item.id === id) {
          return {...item, [target]: value, rewardFormData: formData};
        }
        return item;
      });

      return {...prev, rewards: updatedRewards};
    });
  };

  const handleUploadPhoto = (data: any, type: any, showImage: any) => {
    let formData = new FormData();
    if (typeof data !== "string") {
      formData.append("image", data);
    } else {
      formData.append("imageUrl", data);
    }
    formData.append("name", data.name);
    formData.append("type", type);

    let imgUrl;

    if (typeof data !== "string") {
      imgUrl = URL.createObjectURL(data);
    } else {
      imgUrl = data;
    }

    showImage(imgUrl, formData);
  };

  const handleCreateReward = () => {
    // check if rewards + available rewards is less than 3
    if (leftRewardsAvaialble - rewardData.rewards.length === 0) {
      return toast.error("You can't create more than 3 rewards");
    }

    // Check if rewardData.rewards.length > 0, if it's get the rewardOrder from the last reward, and set id to rewardData.rewards.length + 1, if  rewardData.rewards.length !== 0 then get the rewardOrder from the last reward in the loyaltyProgramLocal and set id to rewardData.rewards.length + 1
    const rewardOrder =
      rewardData.rewards.length > 0
        ? Number(rewardData.rewards[rewardData.rewards.length - 1].id) + 1
        : loyaltyProgramLocal?.rewards?.length > 0
        ? Number(
            loyaltyProgramLocal?.rewards[
              loyaltyProgramLocal?.rewards.length - 1
            ]?.rewardOrder
          ) + 1
        : 1;

    // Create new object in rewards in loyalityData state
    const newReward = {
      rewardOrder: rewardOrder,
      id: `${rewardOrder}`,
      rewardName: "",
      rewardDesc: "",
      expiredAt: expiredDateOriginal,
      stampNeeded: "",
      minAgeReq: "",
      categories: [],
      rewardPhoto: "",
      rewardFormData: null,
      dropdown: false,
      elementChecked: [],
      elementNames: [],
      imageCropModalType: "default",
    };
    setRewardData({
      ...rewardData,
      rewards: [...rewardData.rewards, newReward],
    });
  };

  const deleteReward = (reward: any) => {
    // remove reward and resort all rewards ids, rewards from array in rewardData state
    const newRewards = rewardData.rewards.filter(
      (item) => item.id !== reward.id
    );
    setRewardData({
      ...rewardData,
      rewards: newRewards.map((item, index) => {
        return {...item, id: `${index + 1}`};
      }),
    });
  };

  const resetFormSent = () => setSendForm(false);

  const handleSubmitReward = async () => {
    const loyaltyProgram = dataLoyaltyProgram?.list.find(
      (item: any) =>
        Number(item.loyaltyId) === Number(rewardData.loyalityProgram)
    );

    const rewards = loyaltyProgram?.rewards;

    if (
      rewardData.rewards.length === 1 &&
      rewardData.rewards[0].rewardOrder !== 1
    ) {
      const lastRequiredStamps = rewards[rewards.length - 1].requiredStamps;

      if (Number(rewardData.rewards[0].stampNeeded) < lastRequiredStamps) {
        resetFormSent();
        return toast.error(
          `The stamp needed ${rewardData.rewards[0].stampNeeded} must be greater than ${lastRequiredStamps}`
        );
      }
    }

    // Check in rewards if is .length > 1 each reward if the stampNeeded is greater than the previous one
    if (rewardData.rewards && rewardData.rewards.length > 1) {
      rewardData.rewards.forEach((item, index) => {
        // If index is 0, check the previous requiredStamps in the first reward in the loyaltyProgram
        if (index === 0 && rewards) {
          const lastRequiredStamps = rewards[rewards.length - 1].requiredStamps;

          if (Number(item.stampNeeded) < lastRequiredStamps) {
            resetFormSent();
            return toast.error(
              `The stamp needed ${item.stampNeeded} must be greater than ${lastRequiredStamps}`
            );
          }
        }

        if (index > 0) {
          const previousStampNeeded = rewardData.rewards[index - 1].stampNeeded;

          if (Number(item.stampNeeded) < Number(previousStampNeeded)) {
            resetFormSent();
            return toast.error(
              `The stamp needed ${item.stampNeeded} must be greater than ${previousStampNeeded}`
            );
          }
        }
      });
    }

    if (!merchantID) {
      resetFormSent();
      toast.error("You must select a merchant");
      return;
    }

    // Check if all required fields are filled rewardData
    const isAllRequiredFieldsFilled = rewardData.rewards.every((item) => {
      return (
        item.rewardName &&
        item.rewardDesc &&
        item.minAgeReq &&
        item.stampNeeded &&
        item.categories &&
        item.rewardPhoto
      );
    });

    // console.log(isAllRequiredFieldsFilled);

    if (!rewardData.loyalityProgram || !isAllRequiredFieldsFilled) {
      resetFormSent();
      setInputError(true);
      toast.error("You must fill all the required fields");
      return;
    }

    rewardData.rewards.forEach(async (item, key) => {
      const arrCategoriesID = item?.elementChecked?.map((el: any) => {
        if (el === "all") {
          return dataCategories.map((category: {id: number; name: string}) => {
            // @ts-ignore
            return {id: parseInt(category.id)};
          });
        } else {
          return {id: parseInt(el)};
        }
      });

      let categoryArr = [];

      if (arrCategoriesID[0]?.length > 1) {
        categoryArr = [...arrCategoriesID[0]];
      } else {
        categoryArr = arrCategoriesID;
      }

      const dataToSend = {
        rewardOrder: item.rewardOrder,
        name: item.rewardName,
        description: item.rewardDesc,
        requiredStamps: parseInt(item.stampNeeded),
        ageLimit: parseInt(item.minAgeReq),
        categories: categoryArr,
        expiredAt: expiredDateOriginal,
        // loyaltyId: parseInt(rewardData.loyalityProgram),
        // @ts-ignore
        retailerId: parseInt(merchantID),
      };

      const toastPromise: Promise<any> = toast.promise(
        createRewardMutation({
          idLoyalty: rewardData.loyalityProgram,
          data: dataToSend,
        }),
        {
          pending: "Creating Reward",
        }
      );

      await toastPromise
        .then(async (res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while creating reward</h2>
                <p className="text-xs"> {res?.error?.data?.code} </p>
              </div>
            );
          } else {
            toast.success("Reward Created");

            await handleSendPhoto(item, res.data.reward.prizeId, key);
          }
        })
        .catch((err) => {
          toast.error("Error while creating prize");
        })
        .finally(() => {
          resetFormSent();
        });
    });
  };

  const handleSendPhoto = async (item: any, id: number, key: number) => {
    const toastPromiseImage = toast.promise(
      updateImageItem({
        data: item.rewardFormData,
        idMerchant: merchantID,
        idItem: id,
      }),
      {
        pending: "Uploading Reward Image",
      }
    );
    await toastPromiseImage
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while uplading image {key + 1}</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success(`Reward image ${key + 1} uploaded`);

          dispatch(setSubMerchantTabIndex("3"));

          dispatch(
            setLoyaltyProgram({
              loyaltyId: Number(rewardData.loyalityProgram),
            })
          );

          setTimeout(() => {
            navigate(`/merchants/${merchantID}`);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error while uplading image ${key + 1}`);
      });
  };

  // Función para cambiar el valor dropdown de una recompensa
  const toggleRewardDropdown = (rewardIndex: number) => {
    const updatedRewards = [...rewardData.rewards];
    updatedRewards[rewardIndex].dropdown =
      !updatedRewards[rewardIndex].dropdown;

    setRewardData({
      ...rewardData,
      rewards: updatedRewards,
    });
  };

  const handleImageCropModalType = (rewardIndex: number, type: string) => {
    const updatedRewards = rewardData.rewards.map((reward, index) => {
      if (index === rewardIndex) {
        return {...reward, imageCropModalType: type};
      }
      return reward;
    });

    setRewardData((prevData) => {
      const updatedRewards = prevData.rewards.map((reward, index) => {
        if (type === "BIG" && reward.imageCropModalType === "alreadyCropped")
          return reward;

        if (index === rewardIndex) {
          return {...reward, imageCropModalType: type};
        }
        return reward;
      });

      return {...prevData, rewards: updatedRewards};
    });
  };

  const handleCheckTypeParent = (element: any) => {
    if (element?.child) return element?.childId;
    if (element?.parent) return element?.parentId;
    if (element?.root) return element?.rootId;
  };

  const handleCheckTypeParentName = (element: any) => {
    if (element?.root) return element?.root;
    if (element?.parent) return element?.parent;
    if (element?.child) return element?.child;
  };

  const handleCalculateLeftRewards = (id: number) => {
    if (dataLoyaltyProgram) {
      const loyaltyProgram = dataLoyaltyProgram?.list.find(
        (item: any) => Number(item.loyaltyId) === Number(id)
      );

      if (loyaltyProgram) {
        const rewards = loyaltyProgram?.rewards;
        const rewardsCreated = rewards?.length;

        // Check if the loyaltyProgram.endDate is less than today
        const isExpired = new Date(loyaltyProgram?.endDate) < new Date();

        setIsExpired(isExpired);

        setLoyaltyProgramLocal(loyaltyProgram);

        if (rewardsCreated === 3) return setLeftRewardsAvaialble(0);

        if (!rewardsCreated) return setLeftRewardsAvaialble(3);

        const leftRewards = 3 - rewardsCreated;

        setLeftRewardsAvaialble(leftRewards);
      }
    }
  };

  return (
    <div className="flex  flex-wrap lg:flex-nowrap">
      <div className="flex mt-8 w-6/7 flex-col">
        <div>
          <hr />
          <div className="my-5 flex gap-6">
            <div className="w-1/2">
              <Subtitle>Loyalty Programs</Subtitle>
              <p className="text-xs text-gray-400">
                Select loyalty program what you created before
              </p>
              <div className="flex items-end w-full">
                <Select
                  label="Loyalty Program"
                  required
                  error={
                    !rewardData.loyalityProgram && inputError ? true : false
                  }
                  value={rewardData.loyalityProgram}
                  onChange={(e: any) => {
                    handleCalculateLeftRewards(e.target.value);

                    setRewardData({
                      loyalityProgram: e.target.value,
                      rewards: [],
                    });
                  }}
                  classes="my-6 w-full"
                  classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 text-black w-full"
                >
                  <>
                    <option value="" disabled>
                      {isLoadingLoyaltyProgram
                        ? "Loading..."
                        : "Choose a Loyalty Program"}
                    </option>
                    {dataLoyaltyProgram?.list.map((item: any) => (
                      <option value={item.loyaltyId}>{item.title}</option>
                    ))}
                  </>
                </Select>
                <button
                  className="mb-7 bg-[#f4f7ff] p-1 rounded-md border-none ml-3"
                  type="button"
                  onClick={() => {
                    dispatch(setSubMerchantTabIndex("3"));

                    dispatch(
                      setLoyaltyProgram({
                        loyaltyId: Number(rewardData.loyalityProgram),
                      })
                    );

                    setTimeout(() => {
                      navigate(`/merchants/${merchantID}`);
                    }, 500);
                  }}
                >
                  <Upload classes="text-blue-400" />
                </button>
              </div>
            </div>
            <div className="w-1/2">
              <Subtitle>Rewards</Subtitle>
              <p className="text-xs text-gray-400">
                Rewards created in this loyalty program
              </p>
              {/* Map trought the rewards in lotaltyProgramLocal and show the information  */}
              <div className="flex gap-4 mt-4 flex-wrap">
                {loyaltyProgramLocal &&
                  loyaltyProgramLocal?.rewards?.map(
                    (item: any, index: number) => (
                      <div className="flex flex-col gap-2 border-solid border-gray-200 border p-3 rounded-sm text-sm h-fit w-auto max-w-[250px]">
                        <p>
                          Reward <b>{item?.rewardOrder}</b>
                        </p>
                        <div className="gap-3">
                          <p className="truncate">
                            Name: <b>{item?.newData?.name || item?.name}</b>
                          </p>
                        </div>
                        <div className="gap-3">
                          <p className="truncate">
                            Stamps:{" "}
                            <b>
                              {item?.newData?.requiredStamps ||
                                item?.requiredStamps}
                            </b>
                          </p>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
          <hr />
          {isExpired && (
            <div className="mt-5">
              <p>
                The loyalty program is expired, update it to create new rewards
              </p>
            </div>
          )}
          {dataLoyaltyProgram &&
            leftRewardsAvaialble === 0 &&
            !isExpired &&
            rewardData.loyalityProgram && (
              <div className="mt-5">
                <p>
                  You have reach the max amount of Rewards for this loyalty
                  program
                </p>
              </div>
            )}
          {dataLoyaltyProgram &&
            rewardData.loyalityProgram &&
            leftRewardsAvaialble > 0 &&
            rewardData.rewards &&
            rewardData.rewards.length > 0 &&
            !isExpired &&
            rewardData.rewards.map((item, index) => (
              <>
                <div className="flex w-full mt-5" key={index}>
                  <div className="w-full">
                    <div className="flex  w-full gap-10">
                      <div className="w-full">
                        <p className="font-medium">Reward {item.id}</p>
                        <div className="my-5">
                          <div className="flex justify-between  items-end">
                            <div className="flex">
                              <label
                                htmlFor=""
                                className="block text-sm font-medium text-gray-400"
                              >
                                Reward Name
                              </label>
                              <span className="text-red-500 ml-1">*</span>
                            </div>
                            <p className="font-medium text-sm text-gray-800">{`${rewardData.rewards[index].rewardName.length}/92`}</p>
                          </div>
                          <Input
                            label={""}
                            error={
                              !rewardData.rewards[index].rewardName &&
                              inputError
                                ? true
                                : false
                            }
                            maxLength={92}
                            max={92}
                            placeholder="Provide reward name"
                            value={rewardData.rewards[index].rewardName}
                            disabled={isInputDisabled}
                            onChange={(e: any) => {
                              updateLoyalityData(
                                e.target.value,
                                item.id,
                                "rewardName"
                              );
                            }}
                            classes="min-w-[60%] mr-6 w-full"
                          />
                        </div>
                        <div className="my-5">
                          <div className="flex justify-between  items-end">
                            <div className="flex">
                              <label
                                htmlFor=""
                                className="block text-sm font-medium text-gray-400"
                              >
                                Reward Description
                              </label>
                              <span className="text-red-500 ml-1">*</span>
                            </div>
                            <p className="font-medium text-sm text-gray-800">{`${rewardData.rewards[index].rewardDesc.length}/74`}</p>
                          </div>
                          <Textarea
                            label=""
                            error={
                              !rewardData.rewards[index].rewardDesc &&
                              inputError
                                ? true
                                : false
                            }
                            placeholder="Include any details, terms, and restrictions"
                            rows={8}
                            maxLength={74}
                            value={rewardData.rewards[index].rewardDesc}
                            onChange={(e) => {
                              updateLoyalityData(
                                e.target.value,
                                item.id,
                                "rewardDesc"
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <p className="font-medium invisible">-</p>
                        <Dropzone
                          label="Reward photo"
                          required={true}
                          handleUploadPhoto={handleUploadPhoto}
                          type={`BIG`}
                          image={rewardData.rewards[index].rewardPhoto}
                          classesBorder={`${
                            !rewardData.rewards[index].rewardPhoto &&
                            inputError &&
                            "!border-red-500"
                          }`}
                          imageSetter={
                            // Set image in rewards[index].rewardPhoto
                            (image: any, formData: any) => {
                              updateLoyalityImage(
                                image,
                                item.id,
                                "rewardPhoto",
                                formData
                              );
                            }
                          }
                          id={item.id}
                          imageCropModalType={
                            rewardData.rewards[index].imageCropModalType
                          }
                          setImageCropModalType={(type) =>
                            handleImageCropModalType(index, type)
                          }
                          classesLabel="mt-3"
                        />
                      </div>
                    </div>
                    <p className="font-medium">Reward Parameters</p>
                    <p className="font-medium text-xs text-gray-500">
                      Specify the parameters you want to apply to this item.
                    </p>
                    <div className="w-3/4 gap-8 flex">
                      <div className="w-1/2">
                        <DatePicker
                          label="Expiration date"
                          required
                          disabled={true}
                          value={
                            getNextDayEndDate(expiredDateOriginal) ||
                            expiredDateOriginal.substring(0, 10)
                          }
                          onChange={(e: any) => {}}
                          classes="mt-6 mb-1"

                          // min={addDays(getTodayDate(), 1)}
                          // max={addDays(getTodayDate(), 365)}
                        />
                        {/* <p className="text-xs text-gray-400">
                          When creating a reward, please note that the end date
                          must be the same or less than the end date of the
                          loyalty program.
                        </p> */}
                        <Select
                          label="Minimum age requirements"
                          required
                          error={
                            !rewardData.rewards[index].minAgeReq && inputError
                              ? true
                              : false
                          }
                          value={rewardData.rewards[index].minAgeReq}
                          onChange={(e: any) =>
                            updateLoyalityData(
                              e.target.value,
                              item.id,
                              "minAgeReq"
                            )
                          }
                          classes="my-6"
                          classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 text-black"
                        >
                          <>
                            <option value="" disabled>
                              Select Min. Age
                            </option>
                            <option value={18}>18+</option>
                            <option value={21}>21+</option>
                            <option value={25}>25+</option>
                            <option value={30}>30+</option>
                            <option value={40}>40+</option>
                          </>
                        </Select>
                      </div>
                      <div className="w-1/2">
                        <Input
                          label={"Stamps Needed"}
                          required
                          error={
                            !rewardData.rewards[index].stampNeeded && inputError
                              ? true
                              : false
                          }
                          placeholder="ex. 100"
                          value={rewardData.rewards[index].stampNeeded}
                          disabled={isInputDisabled}
                          onChange={(e: any) => {
                            updateLoyalityData(
                              e.target.value,
                              item.id,
                              "stampNeeded"
                            );
                          }}
                          classes="my-6"
                        />
                        {/* <p className="text-xs text-gray-400 invisible">
                          When creating a reward, please note that the end date
                          must be the same or less than the end date of the
                          loyalty program.
                        </p> */}
                        <DropdownCategory
                          hasLabel={true}
                          label="Categories"
                          openDropdown={() => toggleRewardDropdown(index)}
                          dropdown={rewardData.rewards[index].dropdown}
                          elementsChecked={
                            rewardData.rewards[index].elementChecked
                          }
                          elementsName={rewardData.rewards[index].elementNames}
                          handleClickCheckbox={(e: any) =>
                            handleClickCheckboxForReward(index, e)
                          }
                          dataCategories={getFilteredNames(
                            dataCategories,
                            dropdownSearchFilter[
                              `reward${
                                index + 1
                              }` as keyof typeof dropdownSearchFilter
                            ],
                            ""
                          )}
                          disabled={isInputDisabled}
                          isErrorCategories={isErrorCategories}
                          isLoadingCategories={isLoadingCategories}
                          classNameSelect="w-full"
                          error={error}
                          required={true}
                          showNames={false}
                          isError={
                            !rewardData.rewards[index]?.elementChecked
                              ?.length && inputError
                          }
                          handleClickDropdown={(e: any, type: string) =>
                            handleClickDropdown(e, type, index)
                          }
                          expandItems={
                            dropdownSearchFilter[
                              `reward${
                                index + 1
                              }` as keyof typeof dropdownSearchFilter
                            ]
                          }
                          hasSearchValue={{
                            show: true,
                            content: (
                              <div className="flex mb-3 justify-between">
                                <div className="flex items-center w-full">
                                  <label htmlFor="search">
                                    <Search classes="h-6 w-6 mr-2" />
                                  </label>
                                  <input
                                    id="search"
                                    name="search"
                                    type="text"
                                    placeholder="Search category"
                                    className="decoration-none outline-none w-full"
                                    onChange={(e: any) =>
                                      setDowpdownSearchFilter({
                                        ...dropdownSearchFilter,
                                        [`reward${
                                          index + 1
                                        }` as keyof typeof dropdownSearchFilter]:
                                          e.target.value,
                                      })
                                    }
                                    value={
                                      dropdownSearchFilter[
                                        `reward${
                                          index + 1
                                        }` as keyof typeof dropdownSearchFilter
                                      ]
                                    }
                                  />
                                </div>
                              </div>
                            ),
                          }}
                        />
                        <div
                          className={`mt-3 gap-2 max-h-[400px] overflow-auto  ${
                            rewardData.rewards[index]?.elementChecked?.length >
                            0
                              ? "border border-gray-400"
                              : "border-none"
                          } rounded-md`}
                        >
                          {getTreeCategory(
                            rewardData.rewards[index]?.elementChecked
                          ).map((element: any, i: number) => (
                            <div
                              className="flex justify-between gap-2 items-center border-b-2 border-b-slate-200 p-2"
                              key={i}
                            >
                              <Tooltip id="my-tooltip"></Tooltip>
                              <div className="flex flex-wrap">
                                {element?.root && (
                                  <div
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={`ID:  ${element?.rootId}`}
                                    className="flex items-center text-sm"
                                  >
                                    {element?.root}{" "}
                                    <ArrowNext classes="h-4 w-4" />
                                  </div>
                                )}
                                {element?.parentId && (
                                  <div
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={`ID:  ${element?.parentId}`}
                                    className="flex items-center text-sm"
                                  >
                                    {element?.parent}{" "}
                                    <ArrowNext classes="h-4 w-4" />
                                  </div>
                                )}
                                {element?.childId && (
                                  <div
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={`ID:  ${element?.childId}`}
                                    className="font-medium text-sm"
                                  >
                                    {element?.child}
                                  </div>
                                )}
                              </div>
                              <Button
                                type="button"
                                onClick={() =>
                                  handleClickCheckboxForReward(index, {
                                    target: {
                                      id: handleCheckTypeParent(element),
                                      name: handleCheckTypeParentName(element),
                                      checked: false,
                                    },
                                  })
                                }
                                classes="ml-6 text-blue-400 text-sm"
                              >
                                Remove
                              </Button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  variant={`danger`}
                  onClick={() => deleteReward(item)}
                  type="button"
                >
                  <Delete />
                  <p className="ml-2">Delete</p>
                </Button>
                <hr className="mt-5" />
              </>
            ))}
        </div>
        <div className="mt-10 mb-5">
          {dataLoyaltyProgram &&
            rewardData.loyalityProgram &&
            !isExpired &&
            leftRewardsAvaialble > 0 && (
              <Button
                variant={`${leftRewardsAvaialble > 0 ? "normal" : "disabled"}`}
                onClick={
                  leftRewardsAvaialble > 0 ? handleCreateReward : () => {}
                }
                type="button"
              >
                +<p className="ml-2">Add more reward</p>
              </Button>
            )}
        </div>
      </div>
    </div>
  );
}

export default Reward;
