import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

// Components
import Input from "components/Common/Input";
import DatePicker from "components/Common/Input/DatePicker";
import Select from "components/Common/Input/Select";
import Dropzone from "components/Common/Input/Dropzone";

// Redux
import {
  useUpdateImageItemMutation,
  useCreatePrizeMutation,
} from "store/api/itemApi";
import DropdownCategory from "components/Common/DropdownCategory";
import Textarea from "components/Common/Textarea";
import {addDays} from "utils/addDays";
import {getTodayDate} from "utils/getTodayDate";
import debounce from "lodash.debounce";
import Search from "assets/Search";
import {ArrowNext} from "assets/Arrows";
import Button from "components/Common/Button";
import {findItemById, getFilteredNames} from "utils/workingHoursUtils";
import routes from "utils/routesByRole";
import {useGetCategoriesTreeQuery} from "store/api/categoriesApi";
import {useSelector} from "react-redux";
import {RootState} from "store/store";
import {Tooltip} from "react-tooltip";

interface IPrize {
  sendForm: boolean;
  setSendForm: Function;
  checked: string;
  merchantID: string | number | null;
}

function Prize({sendForm, setSendForm, checked, merchantID}: IPrize) {
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user.user.user);

  const [itemData, setItemData] = useState<{
    prizeType: string;
    price: string;
    name: string;
    description: string;
    offerDiscount: number;
    radius: string;
    perCustomerLimit: number;
    percentShop: string;
    expiredAt: string;
    ageLimit: number;
    categories: {id: number}[];
    promotionType: string;
    prizeCount: string;
    terms: string;
  }>({
    prizeType: "",
    name: "",
    description: "",
    price: "",
    offerDiscount: 0,
    radius: "",
    perCustomerLimit: 0,
    percentShop: "",
    expiredAt: "",
    ageLimit: 18,
    categories: [{id: 0}],
    promotionType: "",
    prizeCount: "",
    terms: "",
  });
  const [expDate, setExpDate] = useState();

  const [itemsShop, setItemsShop] = useState("");
  const [itemsWheel, setItemsWheel] = useState("");
  const [errorInItemsCount, setErrorInItemsCount] = useState(false);

  const [firstImage, setFirstImg] = useState();
  const [secondImage, setSecondImg] = useState();
  const [arrayImageToUpload, setArrayImageToUpload] = useState<any>([]);
  const [objectImages, setObjectImages] = useState<{big: any; small: any}>({
    big: "",
    small: "",
  });

  const [dropdown, openDropdown] = useState(false);
  const [elementsChecked, setElementsChecked] = useState<string[]>([]);
  const [elementsName, setElementsName] = useState<string[]>([]);
  const [elementsParentName, setElementsParentName] = useState<any[]>([]);
  const [dropdownSearchFilter, setDowpdownSearchFilter] = useState("");

  const [inputError, setInputError] = useState(false);

  const [isInputDisabled] = useState(() => {
    //  @ts-ignore
    return routes[user.data.role].urlAllowed?.item?.includes("add")
      ? false
      : true;
  });

  const [imageCropModalType, setImageCropModalType] = useState("default");

  const [
    createPrize,
    {
      data: dataCreateNewItem,
      isLoading: isLoadingCreateNewItem,
      isError: isErrorCreateNewItem,
    },
  ] = useCreatePrizeMutation();

  const {
    data: dataCategories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    error,
    // @ts-ignore
  } = useGetCategoriesTreeQuery();

  const [updateImageItem] = useUpdateImageItemMutation();

  // Send form
  useEffect(() => {
    if (sendForm && checked === "prize") {
      handleCreatePrize();
    }
  }, [sendForm, checked]);

  useEffect(() => {
    if (parseInt(itemsWheel || "0") + parseInt(itemsShop || "0") === 100) {
      setErrorInItemsCount(false);
    }
  }, [itemsShop, itemsWheel]);

  useEffect(() => {
    if (!dataCategories) return;
    const res = elementsChecked.map((id: any, index: number) => {
      let rootParentElement;

      const currentElement = findItemById(dataCategories, id.toString());

      if (!currentElement?.parentId) {
        return {
          id: elementsChecked[index],
          root: currentElement.name,
          rootId: currentElement.id,
        };
      }

      const parentElement = findItemById(
        dataCategories,
        currentElement.parentId.toString()
      );

      if (parentElement?.parentId) {
        rootParentElement = findItemById(
          dataCategories,
          parentElement.parentId.toString()
        );
      }

      // Save the root parent element and the parent element nested
      if (rootParentElement) {
        return {
          id: elementsChecked[index],
          root: rootParentElement.name,
          rootId: rootParentElement.id,
          parent: parentElement.name,
          parentId: parentElement.id,
          child: currentElement.name,
          childId: currentElement.id,
        };
      } else {
        return {
          id: elementsChecked[index],
          parent: parentElement.name,
          parentId: parentElement.id,
          child: currentElement.name,
          childId: currentElement.id,
        };
      }
    });

    setElementsParentName(res);
  }, [elementsChecked, dataCategories]);

  const handleClickCheckbox = (e: any) => {
    const {id, name, checked} = e.target;

    if (id !== "all") {
      if (elementsChecked.includes("all")) {
        setElementsChecked([id]);
        setElementsName([name]);
      } else {
        setElementsChecked([...elementsChecked, id]);
        setElementsName([...elementsName, name]);
      }

      if (!checked) {
        setElementsChecked(elementsChecked.filter((item: any) => item !== id));
        setElementsName(elementsName.filter((item: any) => item !== name));
      }
    } else {
      setElementsChecked(["all"]);
      setElementsName(["All"]);

      if (!checked) {
        setElementsChecked(
          elementsChecked.filter((item: any) => item !== "all")
        );
        setElementsName(elementsName.filter((item: any) => item !== "All"));
      }
    }
  };

  const handleClickDropdown = (e: any, type: string) => {
    e.preventDefault();
    if (type === "save") {
      openDropdown(false);
      return;
    }

    setElementsChecked([]);
    setElementsName([]);
  };

  const parseToType = (x: string) => {
    return parseInt(x);
  };

  const resetFormSent = () => setSendForm(false);

  const handleCreatePrize = async () => {
    setSendForm(false);

    if (!merchantID) {
      resetFormSent();
      toast.error("You must select a merchant");
      return;
    }

    if (
      !itemData.name ||
      !itemData.price ||
      !itemData.radius ||
      !itemData.prizeCount ||
      !itemsShop ||
      !itemsWheel ||
      !itemData.terms ||
      !elementsChecked.length ||
      !expDate ||
      !firstImage ||
      !secondImage
    ) {
      setInputError(true);
      resetFormSent();
      toast.error("You must fill all the required fields");
      return;
    }

    if (parseToType(itemsWheel) + parseToType(itemsShop) !== 100) {
      toast.error("Percent of items in wheel and items in shop must equal 100");
      resetFormSent();
      setErrorInItemsCount(true);
      return;
    }

    setErrorInItemsCount(false);

    const arrCategoriesID = elementsChecked?.map((el: any) => {
      if (el === "all") {
        return dataCategories.map((category: {id: number; name: string}) => {
          // @ts-ignore
          return {id: parseInt(category.id)};
        });
      } else {
        return {id: parseInt(el)};
      }
    });

    let categoryArr = [];

    if (arrCategoriesID[0]?.length > 1) {
      categoryArr = [...arrCategoriesID[0]];
    } else {
      categoryArr = arrCategoriesID;
    }

    const date = `${expDate} 20:59:59`;

    const dataToSend = {
      prizeType: itemData.prizeType,
      name: itemData.name,
      description: itemData.description,
      price: parseInt(itemData.price),
      prizeCount: parseInt(itemData.prizeCount),
      radius: parseInt(itemData.radius),
      // @ts-ignore
      ...(parseInt(itemData.perCustomerLimit) !== 0 && {
        // @ts-ignore
        perCustomerLimit: parseInt(itemData.perCustomerLimit),
      }),
      // @ts-ignore
      ageLimit: parseInt(itemData.ageLimit),
      // @ts-ignore
      percentShop: parseInt(itemsShop),
      promotionType: itemData.promotionType,
      categories: categoryArr,
      expiredAt: new Date(date)?.toISOString(),
      terms: itemData.terms,
    };

    const toastPromise = toast.promise(
      createPrize({
        idMerchant: merchantID,
        data: dataToSend,
      }),
      {
        pending: "Creating Prize",
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while creating prize</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          toast.success("Prize Created");

          const imagesUpload = arrayImageToUpload.map(
            (imageToUpload: any, key: number) => {
              updateItemImages(imageToUpload, res?.data?.prizeId, key);
            }
          );

          Promise.all(imagesUpload).then(() => {
            setTimeout(() => {
              navigate("/items/" + res?.data?.prizeId, {
                state: {
                  idMerchant: res?.data?.retailerId,
                  idItem: res?.data?.prizeId,
                },
              });
            }, 3000);
          });
        }
      })
      .catch((err) => {
        toast.error("Error while creating prize");
      })
      .finally(() => {
        resetFormSent();
      });
  };

  const updateItemImages = async (
    imageToUpload: any,
    id: number,
    key: number
  ) => {
    const toastPromiseImage = toast.promise(
      updateImageItem({
        ...imageToUpload,
        idMerchant: merchantID,
        idItem: id,
      }),
      {}
    );
    await toastPromiseImage
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while uplading image {key}</h2>
              <p className="text-xs"> {res?.error?.data?.code} </p>
            </div>
          );
        } else {
          const messages = ["Item Photo Big", "Item Photo Small"];
          toast.success(`${messages[key]} uploaded`);
        }
      })
      .catch((err) => {
        toast.error(`Error while uplading image ${key}`);
      });
  };

  useEffect(() => {
    const arrFormData = [{data: objectImages.big}, {data: objectImages.small}];

    setArrayImageToUpload(arrFormData);
  }, [objectImages]);

  const handleUploadPhoto = (data: any, type: any, showImage: any) => {
    let formData = new FormData();
    formData.append("image", data);
    formData.append("name", data.name);
    formData.append("type", type);

    const imgUrl = URL.createObjectURL(data);
    showImage(imgUrl);

    if (type === "BIG") {
      setObjectImages({...objectImages, big: formData});
    }
    if (type === "SMALL") {
      setObjectImages({...objectImages, small: formData});
    }
  };

  return (
    <div className="flex  flex-wrap lg:flex-nowrap">
      <div className="flex mt-8 w-6/7 ">
        <div className="w-1/2 mr-4">
          <Input
            label="Prize headline"
            required={true}
            value={itemData.name}
            maxLength={125}
            onChange={(e: any) =>
              setItemData({...itemData, name: e.target.value})
            }
            error={itemData.name.length === 0 && inputError}
            classes="mb-5"
            placeholder="Provide a prize name"
          />
          <Textarea
            label="Item Description"
            placeholder="Include any details, terms, and restrictions"
            classes="mb-5"
            maxLength={600}
            value={itemData.description}
            onChange={(e: any) => {
              setItemData({
                ...itemData,
                description: e.target.value,
              });
            }}
          />
          <Input
            label="Retail value of prize ($)"
            required={true}
            value={parseInt(itemData.price) === 0 ? "" : itemData.price}
            type="number"
            maxLength={500}
            max={500}
            onChange={(e: any) => {
              if (isNaN(e.target.value)) return;
              if (e.target.value > 500) {
                setItemData({
                  ...itemData,
                  price: "500",
                });
              } else {
                setItemData({
                  ...itemData,
                  price: Math.abs(e.target.value).toString(),
                });
              }
            }}
            error={!itemData.price && inputError}
            classes="mb-5"
            placeholder="Regular price"
          />
          <Input
            label="Add additional quantity"
            required={true}
            type="number"
            min={0}
            maxLength={500}
            max={500}
            value={
              parseInt(itemData.prizeCount) === 0 ? "" : itemData.prizeCount
            }
            onChange={(e: any) => {
              if (isNaN(e.target.value)) return;
              if (e.target.value > 500) {
                setItemData({
                  ...itemData,
                  prizeCount: "500",
                });
              } else {
                setItemData({
                  ...itemData,
                  prizeCount: Math.abs(e.target.value).toString(),
                });
              }
            }}
            error={!itemData.prizeCount && inputError}
            placeholder="Additional quantity want to add"
          />
          <p className="text-xs mb-5 mt-1 text-gray-400">
            You currently have 10 items in stock.
          </p>
          <div className="flex">
            <Input
              label="Prize in wheel (%)"
              required={true}
              min={0}
              max={100}
              maxLength={100}
              value={itemsWheel}
              onChange={(e: any) => {
                if (isNaN(e.target.value)) return;
                if (
                  parseToType(e.target.value) + parseToType(itemsShop) > 100 ||
                  parseToType(e.target.value) > 100
                ) {
                  setItemsWheel(
                    Math.abs(parseToType(itemsShop || "0") - 100).toString()
                  );
                  return;
                }
                setItemsWheel(Math.abs(e.target.value).toString());
              }}
              classes="mb-5 mr-2"
              placeholder="-"
              error={(!itemsWheel && inputError) || errorInItemsCount}
            />
            <Input
              label="Prize in shop (%)"
              required={true}
              min={0}
              max={100}
              maxLength={100}
              value={itemsShop}
              onChange={(e: any) => {
                if (isNaN(e.target.value)) return;
                if (
                  parseToType(e.target.value) + parseToType(itemsWheel) > 100 ||
                  parseToType(e.target.value) > 100
                ) {
                  setItemsShop(
                    Math.abs(parseToType(itemsWheel || "0") - 100).toString()
                  );
                  return;
                }
                setItemsShop(Math.abs(e.target.value).toString());
              }}
              classes="mb-5"
              placeholder="-"
              error={(!itemsShop && inputError) || errorInItemsCount}
            />
          </div>
          <DatePicker
            label="Expiration days"
            value={expDate}
            required={true}
            onChange={(e: any) => {
              setExpDate(e.target.value);
            }}
            error={!expDate && inputError}
            min={addDays(getTodayDate(), 1)}
            max={addDays(getTodayDate(), 365)}
          />
        </div>
        <div className="w-1/2 mr-4">
          <Select
            label="Minimum age requirements"
            value={itemData.ageLimit}
            onChange={(e: any) =>
              setItemData({...itemData, ageLimit: e.target.value})
            }
            classes="mb-6"
            classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 text-black"
          >
            <>
              <option value="" disabled>
                Select Min. Age
              </option>
              <option value={18}>18+</option>
              <option value={21}>21+</option>
              <option value={25}>25+</option>
              <option value={30}>30+</option>
              <option value={40}>40+</option>
            </>
          </Select>
          <Input
            label="Maximum Radius Target miles"
            min={0}
            maxLength={2}
            max={20}
            classes="mb-5"
            required={true}
            value={itemData.radius === "0" ? "" : itemData.radius}
            onChange={(e: any) => {
              if (e.target.value > 20) {
                setItemData({
                  ...itemData,
                  radius: "20",
                });
              } else {
                setItemData({
                  ...itemData,
                  radius: !isNaN(e.target.value)
                    ? Math.abs(e.target.value).toString()
                    : "0",
                });
              }
            }}
            placeholder="Input a number between 1 to 20"
            error={!itemData.radius && inputError}
          />
          <div className="w-full">
            <DropdownCategory
              hasLabel={true}
              label="Categories"
              openDropdown={openDropdown}
              dropdown={dropdown}
              elementsChecked={elementsChecked}
              elementsName={elementsName}
              handleClickCheckbox={handleClickCheckbox}
              dataCategories={getFilteredNames(
                dataCategories,
                dropdownSearchFilter,
                ""
              )}
              disabled={isInputDisabled}
              isErrorCategories={isErrorCategories}
              isLoadingCategories={isLoadingCategories}
              classNameSelect="w-full"
              error={error}
              required={true}
              showNames={false}
              isError={!elementsChecked.length && inputError}
              handleClickDropdown={handleClickDropdown}
              expandItems={dropdownSearchFilter}
              hasSearchValue={{
                show: true,
                content: (
                  <div className="flex mb-3 justify-between">
                    <div className="flex items-center w-full">
                      <label htmlFor="search">
                        <Search classes="h-6 w-6 mr-2" />
                      </label>
                      <input
                        id="search"
                        name="search"
                        type="text"
                        placeholder="Search category"
                        className="decoration-none outline-none w-full"
                        onChange={(e: any) =>
                          setDowpdownSearchFilter(e.target.value)
                        }
                        value={dropdownSearchFilter}
                      />
                    </div>
                  </div>
                ),
              }}
            />
            <div
              className={`mt-3 gap-2 max-h-[400px] overflow-auto  ${
                elementsChecked.length > 0
                  ? "border border-gray-400"
                  : "border-none"
              } rounded-md`}
            >
              {elementsParentName.map((element, index) => (
                <div
                  className="flex justify-between gap-2 items-center border-b-2 border-b-slate-200 p-2"
                  key={index}
                >
                  <Tooltip id="my-tooltip"></Tooltip>
                  <div className="flex flex-wrap">
                    {element?.root && (
                      <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={`ID:  ${element?.rootId}`}
                        className="flex items-center text-sm"
                      >
                        {element?.root} <ArrowNext classes="h-4 w-4" />
                      </div>
                    )}
                    {element?.parentId && (
                      <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={`ID:  ${element?.parentId}`}
                        className="flex items-center text-sm"
                      >
                        {element?.parent} <ArrowNext classes="h-4 w-4" />
                      </div>
                    )}
                    {element?.childId && (
                      <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={`ID:  ${element?.childId}`}
                        className="font-medium text-sm"
                      >
                        {element?.child}
                      </div>
                    )}
                  </div>
                  <Button
                    type="button"
                    onClick={() => {
                      setElementsChecked((prevElementsChecked: string[]) =>
                        prevElementsChecked.filter(
                          (item: string) => item !== element.id
                        )
                      );
                      setElementsName((prevElementsChecked: string[]) =>
                        prevElementsChecked.filter(
                          (item: string) => item !== element.child
                        )
                      );
                    }}
                    classes="ml-6 text-blue-400 text-sm"
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </div>
          </div>
          <Select
            label="Prize Attribute"
            value={itemData.promotionType}
            onChange={(e: any) =>
              setItemData({...itemData, promotionType: e.target.value})
            }
            classes="mt-10"
            classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 text-black"
          >
            <>
              <option value="NONE" selected>
                None
              </option>
              <option value="FREE">Free</option>
              <option value="BOGO">Buy one Get one (BOGO)</option>
              <option value="BUYBACK">Buy Back</option>
            </>
          </Select>
          <Select
            label="Redemptions per user"
            value={itemData.perCustomerLimit}
            onChange={(e: any) =>
              setItemData({...itemData, perCustomerLimit: e.target.value})
            }
            classes="mb-10 mt-6"
            classesSelect="border border-gray-400 py-2 pl-2.5 pr-9 text-black"
          >
            <>
              <option value={-1} selected={true}>
                No limit
              </option>
              <option value={1}>x1</option>
              <option value={2}>x2</option>
              <option value={3}>x3</option>
            </>
          </Select>
          <div className="flex flex-col mb-5">
            <Textarea
              label="Terms & Conditions"
              placeholder="Include any details, terms, and restrictions"
              classes="mb-5"
              required
              maxLength={1500}
              value={itemData.terms}
              onChange={(e: any) => {
                setItemData({
                  ...itemData,
                  terms: e.target.value,
                });
              }}
              error={!itemData.terms && inputError}
            />
          </div>
        </div>
      </div>
      <div className="lg:border-l w-1/2 lg:w-3/8 lg:justify-self-end px-4 mt-2">
        <div>
          <Dropzone
            required
            label="Cover photo"
            handleUploadPhoto={handleUploadPhoto}
            type="BIG"
            image={firstImage}
            imageSetter={setFirstImg}
            classesLabel="mt-5"
            classesBorder={`${!firstImage && inputError && "!border-red-500"}`}
            id="big"
            imageCropModalType={imageCropModalType}
            setImageCropModalType={setImageCropModalType}
          />
          <p className="mb-8 mt-2 text-xs text-gray-400">
            Use your photo or image that’s helps people identify your merchant
            in search result.
          </p>
        </div>
        <div>
          <Dropzone
            required
            label="Photo for prize preview in shop"
            handleUploadPhoto={handleUploadPhoto}
            sizePlaceholder={"1:0.4 @ 331x 140"}
            classesBorder={`${!secondImage && inputError && "!border-red-500"}`}
            type="SMALL"
            image={secondImage}
            imageSetter={setSecondImg}
            id="small"
            imageCropModalType={imageCropModalType}
            setImageCropModalType={setImageCropModalType}
            sizesContainer={{
              minWidth: "260px",
            }}
            sizes={{width: 662, height: 280}}
            aspect={331 / 140}
          />
          <p className="mb-8 mt-2 text-xs text-gray-400">
            Use your photo or image that’s helps people identify your merchant
            in search result.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Prize;
