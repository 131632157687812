import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter as Router} from "react-router-dom";
import {ErrorBoundary} from "react-error-boundary";
import {ToastContainer, Flip} from "react-toastify";
import throttle from "lodash.throttle";

// Vitals
import reportWebVitals from "./reportWebVitals";

// Store
import {Provider} from "react-redux";
import {persistor, store} from "./store/store";
import {PersistGate} from "redux-persist/integration/react";
import {setAuth} from "store/user/userSlice";

// Components
import ErrorFallback from "./components/ErrorFallback";

// Page
import App from "./App";

// Styles
import "./styles/global.scss";
import "react-toastify/dist/ReactToastify.css";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const baseUrl = `https://admin-panel-dev.zazmicdemo.com/v1/`;
// https://admin-panel.winspin.com/ PROD

const getNewAT = async () => {
  const refreshResult = await fetch(
    `${baseUrl}administrator/public/auth/refresh`,
    {
      method: "GET",
      headers: {
        "Refresh-Token": store.getState().user.user.user.refreshToken,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => console.log(err));

  return refreshResult;
};

const throttledHandleRefreshAT = throttle(async () => {
  const res = await getNewAT();
  console.log("refetching");
  if (res.authToken) {
    store.dispatch(
      setAuth({authToken: res.authToken, refreshToken: res.refreshToken})
    );
  }
}, 600000);

const refreshInterval = () => {
  return setInterval(() => {
    throttledHandleRefreshAT();
  }, 600002);
};

window.addEventListener("DOMContentLoaded", refreshInterval, false);

if (!store.getState().user.user.user.isAuth) {
  clearInterval(refreshInterval());
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            transition={Flip}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Router>
            <DndProvider backend={HTML5Backend}>
              <App />
            </DndProvider>
          </Router>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
