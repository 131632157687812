// Logos
function Logo({hide}: any) {
  return (
    <div className="flex w-full pt-9 pb-2 items-center space-x-3 justify-center">
      <img
        src={"/swiggle-logo.png"}
        alt="Logo - WinSpin"
        className={`${!hide ? "px-4 w-32" : "w-20"} `}
      />
      {/* {!hide && <p className="text-2xl leading-6 text-white">WinSpin</p>} */}
    </div>
  );
}

export default Logo;
