import {memo, useEffect, useState} from "react";

// Assets
import {ArrowPrev, ArrowNext} from "assets/Arrows";

// Redux
import type {RootState} from "store/store";
import {useSelector, useDispatch} from "react-redux";
import {setPage as setPageAction} from "store/filters/filtersSlice";
import ReactPaginate from "react-paginate";
import Spinner from "components/Common/Spinner";
import {setPaginationLoading} from "store/table/currentTableDataSlice";
import {setLastVisitedPage} from "store/tabs/tabsSlice";

interface PaginationType {
  countPagination?: number;
}

function Pagination({countPagination = 1}: PaginationType) {
  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) => state.filters.filters);
  const currentTable = useSelector(
    (state: RootState) => state.currentTableData
  );

  const [pageCount, setPageCount] = useState(0);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    setItemsPerPage(filters?.limit?.split("=")[1]);
  }, [filters.limit]);

  useEffect(() => {
    const items = Array.from({length: filters.results}, (_, i) => i + 1) || [1];

    // Fetch items from another resources.
    // const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    // @ts-ignore
    setPageCount(Math.ceil(items?.length / parseInt(itemsPerPage)));
  }, [itemOffset, itemsPerPage, filters.results]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset =
      // @ts-ignore
      (event.selected * parseInt(itemsPerPage)) % filters.results;
    dispatch(setPageAction(`&page=${event.selected + 1}`));
    dispatch(setPaginationLoading(true));
    setItemOffset(newOffset);
    if (!window.location.pathname.includes("/", 1))
      dispatch(setLastVisitedPage(`${event.selected + 1}`));
  };

  return (
    <div className="flex items-center">
      {currentTable.paginationLoading && (
        <Spinner classes="border-l-primary-purple border-r-primary-purple border-t-primary-purple w-5 h-5 mr-5" />
      )}
      <ReactPaginate
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={<ArrowPrev classes="w-5 h-5" />}
        pageClassName={`bg-white rounded-lg py-2 !mr-3 leading-tight border text-sm`}
        pageLinkClassName="px-4 py-2"
        previousClassName="mr-3 block py-2 px-3 text-gray-500 bg-white rounded-lg border hover:bg-gray-100 hover:text-gray-700"
        nextLabel={<ArrowNext classes={"w-5 h-5"} />}
        nextClassName="block py-2 px-3 text-gray-500 bg-white rounded-lg border hover:bg-gray-100 hover:text-gray-700"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="mr-3 text-primary-purple"
        containerClassName="inline-flex items-center"
        activeClassName="active-purple text-white"
        disabledClassName="!text-gray-400 !bg-gray-200 !cursor-not-allowed !hover:text-gray-400 !hover:bg-gray-200"
        disabledLinkClassName="!text-gray-400 !bg-gray-200 !cursor-not-allowed !hover:text-gray-400 !hover:bg-gray-200"
        // @ts-ignore
        renderOnZeroPageCount={null}
        forcePage={
          countPagination && !window.location.pathname.includes("/", 1)
            ? countPagination - 1
            : parseInt(filters?.page?.split("=")[1]) - 1
        }
      />
    </div>
  );
}

export default memo(Pagination);
