// Logos
import Dashboard from "assets/Dashboard";
import Merchant from "assets/Merchant";
import Items from "assets/Items";
import Admin from "assets/Admin";
import Users from "assets/Users";
import Configuration from "assets/Configuration";

// Components
import Item from "./Item";

// Redux
import {useSelector} from "react-redux";
import {RootState} from "store/store";

// Utils
import routes from "utils/routesByRole";
import {useMemo} from "react";
import Categories from "assets/Category";
interface ItemsType {
  id: number;
  name: string;
  svg: JSX.Element;
  url: string;
}

function ListItem({hide}: any) {
  const user = useSelector((state: RootState) => state.user.user.user);

  // @ts-ignore
  const ITEMS: ItemsType[] = useMemo(
    () => [
      // @ts-ignore
      !!routes[user.data?.role]?.urlAllowed?.dashboard
        ? {
            id: 1,
            name: "Dashboard",
            svg: <Dashboard classes={`${hide ? "ml-0" : "ml-0"}`} />,
            url: "",
          }
        : null,
      // @ts-ignore
      !!routes[user.data?.role]?.urlAllowed?.merchant
        ? {
            id: 2,
            name: "Merchants",
            svg: <Merchant classes={`${hide ? "ml-0" : "ml-0"}`} />,
            url: "merchants",
          }
        : null,
      // @ts-ignore
      !!routes[user.data?.role]?.urlAllowed?.item
        ? {
            id: 3,
            name: "Items",
            svg: <Items classes={`${hide ? "ml-0" : "ml-0"}`} />,
            url: "items",
          }
        : null,
      // @ts-ignore
      !!routes[user.data?.role]?.urlAllowed?.item
        ? {
            id: 4,
            name: "Categories",
            svg: <Categories classes={`${hide ? "ml-0" : "ml-0"}`} />,
            url: "categories",
          }
        : null,
      // @ts-ignore
      !!routes[user.data?.role]?.urlAllowed?.user
        ? {
            id: 5,
            name: "Users",
            svg: <Users classes={`${hide ? "ml-0" : "ml-0"}`} />,
            url: "users",
          }
        : null,
      // @ts-ignore
      !!routes[user.data?.role]?.urlAllowed?.admin
        ? {
            id: 6,
            name: "Admins",
            svg: <Admin classes={`${hide ? "ml-0" : "ml-0"}`} />,
            url: "admins",
          }
        : null,
      // @ts-ignore
      !!routes[user.data?.role]?.urlAllowed?.config
        ? {
            id: 7,
            name: "Configuration",
            svg: <Configuration classes={`${hide ? "ml-0" : "ml-0"}`} />,
            url: "configuration",
          }
        : null,
    ],
    [user, hide]
  );

  return (
    <ul
      className={`${
        hide ? "py-2 gap-2" : null
      } mt-12 flex flex-col justify-start items-start w-full pb-5`}
    >
      {ITEMS.map((item) => {
        if (
          item &&
          item.hasOwnProperty("id") &&
          item.hasOwnProperty("name") &&
          item.hasOwnProperty("svg") &&
          item.hasOwnProperty("url")
        ) {
          return (
            <Item
              key={item.id}
              itemName={item.name}
              svg={item.svg}
              url={item.url}
              hide={hide}
            />
          );
        }
        return;
      })}
    </ul>
  );
}

export default ListItem;
