import { createApi } from '@reduxjs/toolkit/query/react'
import { setAuth, setData } from 'store/user/userSlice';
import { store } from 'store/store'
import customFetchBase from './customFetchBase';

// dispatch(setAuth(dataAuth));

interface IRegisterData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  role: string;
}

const baseUrl = `https://admin-panel-dev.zazmicdemo.com/v1/`;

export const authApi: any = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    setAuth: builder.mutation({
      query(data) {
        return {
          url: `administrator/public/auth`,
          method: 'POST',
          body: data
        }
      },
      transformResponse: (data: { authToken: string, refreshToken: string }) =>
        data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const getInfoUser = await fetch(`${baseUrl}administrator/user/profile`, {
            method: 'GET',
            headers: {
              'Auth-Token': data.authToken
            }
          }).then((res) => res.json()).then((data) => data).catch(err => console.log(err))

          dispatch(setAuth(data));
          dispatch(setData(getInfoUser));
        } catch (error) { }
      },
    }),
    getProfileData: builder.query<any, any>({
      query: () => ({
        url: `administrator/user/profile`,
        method: 'GET',
        headers: {
          'Auth-Token': store.getState().user.user.user.token
        }
      })
    }),
    setAuthLong: builder.query({
      query() {
        return {
          url: `administrator/refresh-token/long`,
          method: 'GET',
          headers: {
            'Auth-Token': store.getState().user.user.user.token
          }
        }
      },
      transformResponse: (data: { refreshToken: string }) =>
        data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // console.log(data)
          dispatch(setAuth({ authToken: store.getState().user.user.user.token, refreshToken: data.refreshToken }));
        } catch (error) { }
      },
    }),
    refreshToken: builder.query({
      query() {
        return {
          url: `administrator/public/auth/refresh`,
          method: 'GET',
          headers: {
            "Refresh-Token": store.getState().user.user.user.refreshToken,
          }
        }
      },
      transformResponse: (data: { authToken: string, refreshToken: string }) =>
        data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // console.log(data)
          dispatch(setAuth({ authToken: data.authToken, refreshToken: data.refreshToken }));
        } catch (error) {
          console.log(error)
        }
      },
    }),
    registerUser: builder.mutation({
      query(data: IRegisterData) {
        return {
          url: `administrator/public/user`,
          method: 'POST',
          body: data,
        }
      },
    }),
    forgotPassword: builder.mutation({
      query(email: string) {
        return {
          url: `administrator/public/user/forget-password`,
          method: 'POST',
          body: {
            email
          },
        }
      },
    }),
    resetPassword: builder.mutation<any, any>({
      // @ts-ignore
      query: ({ data, token }) => ({
        url: `administrator/public/user/${token}/reset-password`,
        method: 'POST',
        body: data,
      }),
    }),
    confirmEmail: builder.query<any, any>({
      query: (token: string) => ({
        url: `administrator/public/user/${token}/confirm-email`,
        method: 'POST',
      })
    }),
    approveReqRegister: builder.query<any, any>({
      query: (token: string) => ({
        url: `administrator/public/user/${token}/approve`,
        method: 'POST',
      })
    }),
    declineReqRegister: builder.query<any, any>({
      query: (token: string) => ({
        url: `administrator/public/user/${token}/decline`,
        method: 'POST',
      })
    }),
  }),
})

export const { useSetAuthMutation, useLazyGetProfileDataQuery, useLazySetAuthLongQuery, useRegisterUserMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLazyApproveReqRegisterQuery,
  useLazyDeclineReqRegisterQuery,
  useLazyConfirmEmailQuery,
} = authApi