import {ArrowNext, ArrowPrev} from "assets/Arrows";
import {EyeView} from "assets/Eye";
import Badge from "components/Badge";
import Button from "components/Common/Button";
import Select from "components/Common/Input/Select";
import {useState} from "react";
import ReactPaginate from "react-paginate";
import {useNavigate} from "react-router";
import {toast} from "react-toastify";
import {
  useGetNotificationsQuery,
  useReadNotificationByIDMutation,
} from "store/api/notificationApi";

export interface NotificationProps {
  id: number;
  notificationId: string;
  message: string;
  title: string;
  notificationType: string;
  isRead: boolean;
  createdAt: Date;
  kind: string;
  expiredAt: null;
}

function Activity() {
  const navigate = useNavigate();
  const [dataPagination, setDataPagination] = useState<any>({
    page: 0,
    limit: 10,
  });

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const {
    data: dataNotifications,
    isLoading: isLoadingNotifications,
    isError: isErrorNotifications,
    error,
  } = useGetNotificationsQuery(
    `?page=${dataPagination.page}&limit=${dataPagination.limit}`,
    {
      pollingInterval: 25000,
      skip: false,
    }
  );

  const [readNotificationMutation] = useReadNotificationByIDMutation();

  function timeSince(createdDate: Date): string {
    const now = new Date();
    const createdAt = new Date(createdDate);
    const minutesDiff = Math.floor(
      (now.getTime() - createdAt.getTime()) / 60000
    ); // difference in minutes

    if (minutesDiff < 1) {
      return "< 1 min";
    } else if (minutesDiff < 60) {
      return `${minutesDiff} min${minutesDiff > 1 ? "s" : ""} ago`;
    } else if (minutesDiff < 1440) {
      // less than 24 hours
      const hoursDiff = Math.floor(minutesDiff / 60);
      return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
    } else {
      const daysDiff = Math.floor(minutesDiff / 1440);
      return `${daysDiff} day${daysDiff > 1 ? "s" : ""} ago`;
    }
  }

  const handleViewNotification = (notif: NotificationProps) => {
    const {id, kind} = notif;

    const toastPromise = toast.promise(readNotificationMutation({id}), {});

    const kindSplitted = kind.split("/");

    if (kindSplitted.includes("RETAILER") && kindSplitted.includes("PRIZE")) {
      navigate(`/items/${kindSplitted[3]}`, {
        state: {
          idMerchant: kindSplitted[1],
          idItem: kindSplitted[3],
        },
      });
    }
    if (kindSplitted.includes("PRIZE")) return;
    if (kindSplitted.includes("RETAILER")) {
      navigate(`/merchants/${kindSplitted[1]}/`);
    }

    toastPromise
      .then((res: any) => {})
      .catch((err: any) => {
        console.log("error", err);
      })
      .finally(() => {});
  };

  const handlePageClick = (event: any) => {
    const newOffset =
      // @ts-ignore
      (event.selected * parseInt(itemsPerPage)) % dataNotifications?.count;
    setDataPagination({
      ...dataPagination,
      page: event.selected + 1,
    });
    setItemOffset(newOffset);
  };

  const handleSelectAmountToSee = (e: any) => {
    setDataPagination({
      ...dataPagination,
      limit: e.target.value,
    });
  };

  return (
    <>
      {isLoadingNotifications && <p>Loading...</p>}
      {dataNotifications?.notifications?.map((notif: NotificationProps) => (
        <div
          key={notif?.notificationId}
          className={`flex gap-5 w-full min-h-[150px] py-4 justify-between`}
        >
          <div className="h-full items-start">
            {/* circle div */}
            <div className="rounded-full h-10 w-10 bg-blue-300 flex justify-center items-center">
              LM
            </div>
          </div>
          <div className="flex w-full border-b border-gray-400 pb-6">
            <div className="flex flex-col gap-5 w-full">
              <div className="flex gap-3 items-center">
                <p className="font-semibold text-sm">{notif?.title}</p>
                <b>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="4"
                    viewBox="0 0 4 4"
                    fill="none"
                  >
                    <circle cx="2" cy="2" r="2" fill="#87879A" />
                  </svg>
                </b>
                <p className="text-xs text-center text-gray-400">
                  {timeSince(notif?.createdAt)}
                </p>
              </div>
              <p className="text-xs text-gray-400">{notif?.message}</p>
              <div className="flex flex-col gap-1">
                <p style={{fontSize: "10px"}} className="text-gray-400">
                  STATUS
                </p>
                <div className="flex">
                  <Badge title="new" />
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.3507 12.4964L8.62465 17.2214C8.58671 17.2567 8.55645 17.2995 8.53576 17.3471C8.51507 17.3947 8.50439 17.446 8.50439 17.4979C8.50439 17.5497 8.51507 17.601 8.53576 17.6486C8.55645 17.6962 8.58671 17.739 8.62465 17.7744L9.22465 18.3744C9.26002 18.4123 9.30282 18.4426 9.35039 18.4632C9.39796 18.4839 9.44928 18.4946 9.50115 18.4946C9.55302 18.4946 9.60434 18.4839 9.65191 18.4632C9.69948 18.4426 9.74228 18.4123 9.77765 18.3744L15.3777 12.7744C15.4156 12.739 15.4459 12.6962 15.4665 12.6486C15.4872 12.601 15.4979 12.5497 15.4979 12.4979C15.4979 12.446 15.4872 12.3947 15.4665 12.3471C15.4459 12.2995 15.4156 12.2567 15.3777 12.2214L9.77965 6.61635C9.74428 6.57841 9.70148 6.54815 9.65391 6.52746C9.60634 6.50677 9.55502 6.49609 9.50315 6.49609C9.45128 6.49609 9.39996 6.50677 9.35239 6.52746C9.30482 6.54815 9.26202 6.57841 9.22665 6.61635L8.62665 7.21635C8.58884 7.2518 8.5587 7.29462 8.53809 7.34218C8.51749 7.38974 8.50686 7.44102 8.50686 7.49285C8.50686 7.54468 8.51749 7.59596 8.53809 7.64352C8.5587 7.69108 8.58884 7.7339 8.62665 7.76935L13.3507 12.4964Z"
                      fill="#87879A"
                    />
                  </svg>
                  <Badge title="published" />
                </div>
              </div>
            </div>

            <div className="w-[15%] flex justify-end gap-2">
              <Button
                variant="secondary"
                onClick={() => handleViewNotification(notif)}
              >
                <div className="flex gap-1 items-center">
                  <EyeView />
                  <p className="text-xs font-medium">View</p>
                </div>
              </Button>
              {/* <button className="text-lg text-gray-500">
              <CloseIcon />
            </button> */}
            </div>
          </div>
        </div>
      ))}
      <div className="flex justify-between">
        <Select
          id="numeration"
          classes="flex items-center"
          label="Shows: "
          classesSelect="py-2 px-2  ml-4"
          onChange={(e: any) => handleSelectAmountToSee(e)}
          value={dataPagination.limit}
        >
          <>
            <option defaultValue="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="999999999">All</option>
          </>
        </Select>
        <ReactPaginate
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={Math.ceil(
            dataNotifications?.count / parseInt(dataPagination.limit)
          )}
          previousLabel={<ArrowPrev classes="w-5 h-5" />}
          pageClassName={`bg-white rounded-lg py-2 !mr-3 leading-tight border text-sm`}
          pageLinkClassName="px-4 py-2"
          previousClassName="mr-3 block py-2 px-3 text-gray-500 bg-white rounded-lg border hover:bg-gray-100 hover:text-gray-700"
          nextLabel={<ArrowNext classes={"w-5 h-5"} />}
          nextClassName="block py-2 px-3 text-gray-500 bg-white rounded-lg border hover:bg-gray-100 hover:text-gray-700"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="mr-3 text-primary-purple"
          containerClassName="inline-flex items-center"
          activeClassName="active-purple text-white"
          disabledClassName="!text-gray-400 !bg-gray-200 !cursor-not-allowed !hover:text-gray-400 !hover:bg-gray-200"
          disabledLinkClassName="!text-gray-400 !bg-gray-200 !cursor-not-allowed !hover:text-gray-400 !hover:bg-gray-200"
          // @ts-ignore
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}

export default Activity;
