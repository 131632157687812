import React, {useState, useEffect, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {notificationApi} from "store/api/notificationApi";
import {RootState} from "store/store";

// @ts-ignore
import NotificationSound from "../../assets/livechat.mp3";

export const WebSocketDemo = () => {
  const user = useSelector((state: RootState) => state.user.user.user);
  const dispatch = useDispatch();

  const audioPlayer = useRef(null);

  function playAudio() {
    if (audioPlayer.current === null) return;
    // @ts-ignore
    audioPlayer.current.play();
  }

  const [socketUrl] = useState(
    `wss://admin-panel-dev.zazmicdemo.com/v1/websocket/admin/ws?Auth-Token=${user.token}`
  );

  const {lastMessage, readyState} = useWebSocket(socketUrl);

  useEffect(() => {
    if (lastMessage !== null) {
      dispatch(notificationApi.util.invalidateTags(["NotificationsCount"]));
      playAudio();
    }
  }, [lastMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  console.log("connectionStatus", connectionStatus);

  return <audio ref={audioPlayer} src={NotificationSound} />;
};
