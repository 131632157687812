// Components
import ItemOption from "components/Options";
import MenuItem from "components/Options/MenuItem";
import {useSelector} from "react-redux";
import {RootState} from "store/store";

import {useGetNotificationCountQuery} from "store/api/notificationApi";
import {useNavigate} from "react-router";
import Spinner from "components/Common/Spinner";

function ProfileItem({hide}: any) {
  const navigate = useNavigate();
  const userInfo = useSelector((state: RootState) => state.user.user.user.data);

  const {
    data: dataNotificationsCount,
    isLoading: isLoadingNotificationsCount,
    isError: isErrorNotificationsCount,
    // @ts-ignore
  } = useGetNotificationCountQuery(undefined);

  const handleSignOut = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const getRole = () => {
    const role = userInfo?.role?.toLowerCase();
    if (role) {
      if (role === "superadmin") {
        return "Super Admin";
      } else if (role === "salesadmin") {
        return "Admin";
      } else if (role === "moderator") {
        return "Moderator";
      } else {
        return userInfo?.role;
      }
    }
  };

  const handleRedirectToNotification = () => {
    navigate(`/notifications`);
  };

  return (
    <div className="flex flex-col justify-end items-center h-full w-full space-y-32">
      <div className="w-full bg-primary-purple-dark sticky bottom-0">
        <div
          className={`flex justify-center items-center w-full  pb-5 pt-5 ${
            !hide && "pl-6"
          }`}
        >
          {!hide && (
            <div className="flex justify-center items-center  space-x-2 ">
              <div className="flex justify-start flex-col items-start">
                <p className="leading-5 text-sm text-white font-semibold pb-2">
                  <button
                    className="relative rounded py-1.5 text-xs font-medium cursor-pointer"
                    onClick={handleRedirectToNotification}
                  >
                    <span className="absolute -top-1 -right-4 h-4 w-4 rounded-full bg-red-600 flex justify-center items-center items">
                      <span className="text-xs">
                        {isLoadingNotificationsCount && (
                          <Spinner classes="border-l-white border-r-white border-t-white w-2 h-2 !border-[1.5px] border-solid" />
                        )}
                        {dataNotificationsCount?.aps?.badge ?? 0}
                      </span>
                    </span>
                    <span>
                      {userInfo?.firstName} {userInfo?.lastName}{" "}
                    </span>
                  </button>
                </p>
                <p className="text-xs leading-3 text-slate-500 font-semibold">
                  {getRole()}
                </p>
              </div>
            </div>
          )}
          <ItemOption hide={hide} toTop={true} classes="text-white-200">
            <MenuItem type="resetPassword" title="Reset Password" />
            <MenuItem onClick={handleSignOut} title="Sign out" />
          </ItemOption>
        </div>
      </div>
    </div>
  );
}

export default ProfileItem;
