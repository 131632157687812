import {Link, useLocation, useNavigate, useParams} from "react-router-dom";

// Component
import Button from "components/Common/Button";
import Tabs from "components/Common/Tabs";
import Title from "components/Common/Title";
import Badge from "components/Badge";
import AddCategory from "./Tabs/AddCategory";
import Overview from "./Tabs/Overview";

// Assets
import Save from "assets/Save";
import {ArrowBack} from "assets/Arrows";
import Publish from "assets/Publish";

// Utils
import routes from "utils/routesByRole";

// Store
import {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
  useCreateCategoryPrizeMutation,
  useGetCategoryByIDQuery,
  useGetCategoryPrizeListFromCategoryQuery,
  useUpdateCategoryCategoriesMutation,
} from "store/api/categoriesApi";
import {transformDate} from "utils/transformDate";
import {
  usePublishCategoryLocationMutation,
  useUnpublishCategoryLocationMutation,
} from "store/api/locationApi";
import {EyeHide} from "assets/Eye";
import {toast} from "react-toastify";
import CategoriesInfoSidebar from "components/Sidebar/CategoriesInfoSidebar";
import {useLazyGetIndividualItemByMerchantIDQuery} from "store/api/itemApi";
import {
  setOpenModal,
  setSidebarCategoryOpenModal,
  setType,
} from "store/modal/modalSlice";
import Spinner from "components/Common/Spinner";
import DatePicker from "components/Common/Input/DatePicker";
import Select from "components/Common/Input/Select";
import Dropzone from "components/Common/Input/Dropzone";
import Modal from "components/Modal";
import {Dialog} from "@headlessui/react";
import {setSorting} from "store/filters/filtersSlice";
import Input from "components/Common/Input";
import Edit from "assets/Edit";

function CategoriesDetail() {
  const {id} = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user.user);
  const modal: any = useSelector((state: RootState) => state.modal);
  const filters = useSelector((state: RootState) => state?.filters?.filters);

  const [itemsAdded, setItemsAdded] = useState<{
    prize: any[];
    offer: any[];
  }>({
    prize: [],
    offer: [],
  });

  const [itemsAddedOverview, setItemsAddedOverview] = useState<any>([]);

  const [currentDMAID] = useState(
    filters.lastCategoryLocation.dma || location?.state?.id || null
  );
  const [newIntentAddItem, setNewIntentAddItem] = useState<any>({});
  const [newIntentId, setNewIntentId] = useState<any>(0);
  const [modalContent, setModalContent] = useState<any[]>([]);
  const [typeActionCategory, setTypeActionCategory] = useState("");
  const [newCategoryName, setNewCategoryName] = useState("");

  const [addPrizeMutation] = useCreateCategoryPrizeMutation();
  const [updateCategoryMutation] = useUpdateCategoryCategoriesMutation();

  const [publishCategoryLocationMutation] =
    usePublishCategoryLocationMutation();
  const [unpublishCategoryLocationMutation] =
    useUnpublishCategoryLocationMutation();

  const {data: dataCategory, refetch} = useGetCategoryByIDQuery({
    dma_code: currentDMAID,
    category_id: id,
  });

  const {
    data: dataItemsInCategory,
    isLoading: isLoadingItemsInCategory,
    isError: isErrorItemsInCategory,
    refetch: refetchCategory,
  } = useGetCategoryPrizeListFromCategoryQuery({
    category_id: id,
    dma_code: currentDMAID,
    params: "",
  });

  const [
    getItemData,
    {data: dataItem, isLoading: isLoadingItem, isError: isErrorItem},
  ] = useLazyGetIndividualItemByMerchantIDQuery();

  useEffect(() => {
    if (dataCategory?.categoryName) {
      setNewCategoryName(dataCategory.categoryName);
    }
  }, [dataCategory?.categoryName]);

  useEffect(() => {
    const type = newIntentAddItem?.prizeType;
    const resultPrize = itemsAdded?.prize?.filter(
      (el: any) => el.prizeId !== newIntentAddItem?.prizeId
    );
    const resultOffer = itemsAdded?.offer?.filter(
      (el: any) => el.prizeId !== newIntentAddItem?.prizeId
    );

    if (Object.keys(newIntentAddItem).length === 0) return;

    if (type === "REGULAR") {
      setItemsAdded({
        prize: [...resultPrize, newIntentAddItem],
        offer: [...resultOffer],
      });
    } else {
      setItemsAdded({
        prize: [...resultPrize],
        offer: [...resultOffer, newIntentAddItem],
      });
    }
  }, [newIntentAddItem, newIntentId]);

  useEffect(() => {
    if (
      dataItemsInCategory &&
      dataItemsInCategory.prizes &&
      dataItemsInCategory.prizes.length > 0
    ) {
      setItemsAddedOverview(dataItemsInCategory?.prizes);
    }
  }, [dataItemsInCategory]);

  const handleUpdateCategory = async (type: string) => {
    if (type === "save") {
      const data = [
        ...itemsAdded.prize,
        ...itemsAdded.offer,
        ...itemsAddedOverview,
      ].map((item) => {
        return {prizeId: item.prizeId};
      });

      const toastPromise = toast.promise(
        addPrizeMutation({
          location_id: currentDMAID,
          category_id: id,
          data,
        }),
        {
          pending: `Saving items in category`,
        }
      );

      await toastPromise
        .then((res: any) => {
          if (res.error) {
            toast.error(
              <div>
                <h2>Error while saving items</h2>
                <p className="text-xs"> {res?.error?.data?.message} </p>
              </div>
            );
          } else {
            toast.success(`Items saved`);
            setItemsAdded({
              prize: [],
              offer: [],
            });
            setItemsAddedOverview([]);
            refetch();
            refetchCategory();
            // dispatch(setResetElChecked(true));
          }
        })
        .catch((err) => {
          toast.error("Error while saving items");
        });
    }

    if (type === "publish") {
      setModalContent([dataCategory]);
      dispatch(setType("unpublishCategoryModal"));
      dispatch(setOpenModal(true));
    }

    if (type === "unpublish") {
      setModalContent([dataCategory]);
      dispatch(setType("unpublishCategoryModal"));
      dispatch(setOpenModal(true));
    }
  };

  const updateItemsAdded = (e: any) => {
    const alreadyExistItem = itemsAddedOverview?.find(
      (el: any) => el.prizeId === e.prizeId
    );

    if (alreadyExistItem) return toast.info("Item already exists in category");

    setNewIntentAddItem(e);
    setNewIntentId((prev: any) => prev + 1);
  };

  const updateItemsAddedRemove = (e: any) => {
    // Remove items from the original category
    const resultOriginalCategory = itemsAddedOverview?.filter(
      (el: any) => el.prizeId !== e?.prizeId
    );

    const resultPrize = itemsAdded?.prize?.filter(
      (el: any) => el.prizeId !== e?.prizeId
    );
    const resultOffer = itemsAdded?.offer?.filter(
      (el: any) => el.prizeId !== e?.prizeId
    );

    setItemsAddedOverview(resultOriginalCategory);
    setItemsAdded({
      prize: [...resultPrize],
      offer: [...resultOffer],
    });
  };

  const handleOpenSidebarCategory = (e: any) => {
    getItemData({idMerchant: e.merchantId, idItem: e.prizeId});
    dispatch(setSidebarCategoryOpenModal(true));
  };

  const handleUnpublish = async () => {
    const toastPromise = toast.promise(
      unpublishCategoryLocationMutation({
        dmaCode: currentDMAID,
        categoryId: id,
      }),
      {
        pending: `Unpublishing category`,
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while unpublishing category</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success(`Category unpublished`);
          refetch();
          refetchCategory();
          setTimeout(() => {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
          }, 1000);
          // dispatch(setResetElChecked(true));
        }
      })
      .catch((err) => {
        toast.error("Error while unpublishing category");
      });
  };

  const handlePublish = async () => {
    const result = dataItemsInCategory?.prizes?.length;

    if (result < 5) {
      dispatch(setType("showWarningModalLessThan5"));

      return;
    }

    handleConfirmPublish();
  };

  const handleConfirmPublish = async () => {
    const toastPromise = toast.promise(
      publishCategoryLocationMutation({
        dmaCode: currentDMAID,
        categoryId: id,
      }),
      {
        pending: `Publishing category`,
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while publishing category</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success(`Category published`);
          refetch();
          refetchCategory();
          setTimeout(() => {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
          }, 1000);
          // Invalidate tag CategoriesID in categoriesApi in RTK Query
          // dispatch(categoriesApi.util.invalidateTags(["CategoriesID"]));
        }
      })
      .catch((err) => {
        toast.error("Error while publishing category");
      });
  };

  const handleCloseModal = () => {
    dispatch(setOpenModal(false));
    dispatch(setType(""));
  };

  const handleCloseModalWarning = () => {
    dispatch(setOpenModal(false));
    dispatch(setType(""));
  };

  const handleUpdateCategoryName = async (e: any) => {
    e.preventDefault();

    const data = {
      name: newCategoryName,
    };

    const toastPromise = toast.promise(
      updateCategoryMutation({
        dma_code: currentDMAID,
        category_id: id,
        data,
      }),
      {
        pending: `Updating category name`,
      }
    );

    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while updating category name</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success(`Category name updated`);
          refetch();
          refetchCategory();
          setTimeout(() => {
            dispatch(setType(""));
            dispatch(setOpenModal(false));
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error("Error while updating category name");
      });
  };

  return (
    <section className="mt-3">
      <div className="mb-5">
        <Link
          to={".."}
          onClick={(e) => {
            e.preventDefault();

            dispatch(setSorting("ordering=id"));
            navigate(-1);
          }}
          className="text-sm text-gray-400 flex hover:text-primary-purple max-w-max"
        >
          <ArrowBack classes="h-5 w-5 mr-2" />
          Back to category list
        </Link>
      </div>
      <div className="">
        <div className="flex justify-between">
          <Title classes="flex items-center mb-0">
            <p className="mr-3">{dataCategory?.categoryName}</p>
            <Badge
              title={dataCategory?.status.toLocaleLowerCase()}
              showIcon={false}
            ></Badge>
            {/* {dataItem?.newData && <Alert classes="mr-3" />} {dataItem?.name} */}
          </Title>
          <div className="flex">
            {
              // @ts-ignore
              routes[user.data.role].urlAllowed?.categories?.includes(
                "save"
              ) && (
                <>
                  {dataCategory?.type === "CUSTOM" && (
                    <Button
                      variant="normal"
                      classes="mr-16"
                      onClick={() => {
                        dispatch(setType("updateCategoryName"));
                        dispatch(setOpenModal(true));
                      }}
                    >
                      <Edit />
                      <p className="ml-2">Edit</p>
                    </Button>
                  )}
                  <Button
                    variant="normal"
                    classes="mr-16"
                    onClick={() => handleUpdateCategory("save")}
                  >
                    <Save />
                    <p className="ml-2">Save</p>
                  </Button>
                </>
              )
            }
            {dataCategory?.isPublished
              ? // @ts-ignore
                routes[user.data.role].urlAllowed?.categories?.includes(
                  "publish"
                ) && (
                  <Button
                    variant="normal"
                    classes="mr-16"
                    onClick={() => {
                      handleUpdateCategory("unpublish");
                      setTypeActionCategory("unpublish");
                    }}
                  >
                    <EyeHide />
                    <p className="ml-2">Unpublish</p>
                  </Button>
                )
              : // @ts-ignore
                routes[user.data.role].urlAllowed?.categories?.includes(
                  "publish"
                ) && (
                  <Button
                    variant="add"
                    classes="mr-16"
                    onClick={() => {
                      handleUpdateCategory("publish");
                      setTypeActionCategory("publish");
                    }}
                  >
                    <Publish />
                    <p className="ml-2">Publish</p>
                  </Button>
                )}
          </div>
        </div>
        <p className="text-xs text-gray-800 font-semibold mt-3">
          {dataCategory?.dmaDescription}
        </p>
        <p className="text-xs text-gray-500 mt-3">
          Created: {transformDate(dataCategory?.createdAt)} / Modify date:{" "}
          {transformDate(dataCategory?.updatedAt)}/ Publish:{" "}
          {dataCategory?.isPublished ? "Yes" : "No"}
        </p>
      </div>
      <div className="mt-5">
        <Tabs tabsName={["Add to Category", "Overview"]}>
          <>
            <hr />
            <AddCategory
              dataCategory={dataCategory}
              itemsAdded={itemsAdded}
              setItemsAdded={setItemsAdded}
              itemsAddedOverview={itemsAddedOverview}
              updateItemsAdded={updateItemsAdded}
              handleOpenSidebarCategory={handleOpenSidebarCategory}
              currentDMAID={currentDMAID}
            />
          </>
          <>
            <hr />
            <Overview
              itemsAdded={itemsAdded}
              isLoadingItemsInCategory={isLoadingItemsInCategory}
              isErrorItemsInCategory={isErrorItemsInCategory}
              setItemsAdded={setItemsAdded}
              itemsAddedOverview={itemsAddedOverview}
              setItemsAddedOverview={setItemsAddedOverview}
              updateItemsAddedRemove={updateItemsAddedRemove}
              handleOpenSidebarCategory={handleOpenSidebarCategory}
            />
          </>
        </Tabs>
      </div>
      <CategoriesInfoSidebar>
        <div
          className={`${
            !dataItem ? "h-screen" : "h-fit"
          }  flex flex-col bg-white text-black rounded w-96 py-4 px-5 pt-5`}
        >
          <h2 className="text-xl font-medium mb-1">{dataItem?.name}</h2>
          <p className="text-sm font-medium">{dataItem?.prizeId}</p>
          <hr className="my-5" />
          {isLoadingItem && (
            <div className="flex flex-col justify-center items-center">
              <Spinner classes="border-l-primary-purple border-r-primary-purple border-t-primary-purple w-5 h-5 mr-5 mt-4" />
              <p className="mt-3">Loading informations</p>
            </div>
          )}

          {dataItem && dataItem?.prizeId && (
            <>
              <DatePicker
                label="Expiration days"
                disabled={true}
                checkboxID="expiredAt"
                classes="mb-5"
                value={
                  dataItem?.expiredAt?.includes("T")
                    ? dataItem?.expiredAt?.split("T")[0]
                    : dataItem?.expiredAt
                }
              />

              <Select
                label="Minimum age requirements"
                value={dataItem?.ageLimit}
                checkboxID="ageLimit"
                classes="mb-5"
                disabled={true}
                classesSelect="py-2 pl-2.5 pr-9 text-black"
              >
                <>
                  <option value="" disabled>
                    Select Min. Age
                  </option>
                  <option value={18}>18+</option>
                  <option value={21}>21+</option>
                  <option value={25}>25+</option>
                  <option value={30}>30+</option>
                  <option value={40}>40+</option>
                </>
              </Select>

              <Select
                label="Redemptions per user"
                checkboxID="perCustomerLimit"
                value={dataItem?.perCustomerLimit}
                classes="mb-5"
                disabled={true}
                classesSelect="py-2 pl-2.5 pr-9 text-black"
              >
                <>
                  <option value={-1} selected={true}>
                    No limit
                  </option>
                  <option value={1}>x1</option>
                  <option value={2}>x2</option>
                  <option value={3}>x3</option>
                </>
              </Select>

              <Input
                label="Maximum Radius Target miles"
                min={0}
                maxLength={2}
                max={20}
                required={true}
                classes="mb-5"
                checkboxID="radius"
                value={dataItem?.radius === "0" ? "" : dataItem?.radius}
                placeholder="Input a number between 1 to 20"
                disabled={true}
              />

              <Dropzone
                image={dataItem.images[1].url}
                required={true}
                label="Cover photo"
                type="BIG"
                id="1"
                classesLabel="mt-5"
                classes="mb-8"
                disabledInput={true}
              />
              <Dropzone
                image={dataItem.images[0].url}
                type="SMALL"
                label={`Photo for ${
                  dataItem?.prizeType?.toLowerCase() === "regular"
                    ? "prize"
                    : "offer"
                }`}
                sizePlaceholder={"1:0.4 @ 331x 140"}
                id="3"
                sizesContainer={{
                  minWidth: "260px",
                }}
                sizes={{width: 662, height: 280}}
                aspect={331 / 140}
                disabledInput={true}
              />
            </>
          )}
        </div>
      </CategoriesInfoSidebar>
      {modal.type === "unpublishCategoryModal" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <form>
              <Dialog.Title className="px-6 mb-2 text-black text-xl font-medium">
                Are you sure you want to {typeActionCategory} this category?
              </Dialog.Title>
              <div className="px-6 mb-5">
                {modalContent.map((element: any) => {
                  return <p>- {element?.categoryName}</p>;
                })}
              </div>

              <p className="text-sm px-6">
                This action will affect the customer application
              </p>
              <hr className="my-5" />
              <div className="px-6">
                <div className="w-100 flex justify-between items-center mt-4">
                  <Button
                    type="button"
                    variant="normal"
                    onClick={() => handleCloseModal()}
                  >
                    <p>Cancel</p>
                  </Button>
                  {typeActionCategory === "unpublish" ? (
                    <Button
                      variant={
                        modalContent[0]?.isPublished !== false
                          ? "add"
                          : "disabled"
                      }
                      type="button"
                      onClick={
                        modalContent[0]?.isPublished !== false
                          ? handleUnpublish
                          : () =>
                              console.log(
                                "This category is already unpublished"
                              )
                      }
                    >
                      <p>Unpublish</p>
                    </Button>
                  ) : (
                    <Button
                      variant={
                        modalContent[0]?.isPublished !== true
                          ? "add"
                          : "disabled"
                      }
                      type="button"
                      onClick={
                        modalContent[0]?.isPublished !== true
                          ? handlePublish
                          : () =>
                              console.log("This category is already published")
                      }
                    >
                      <p>Publish</p>
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
      {modal.type === "showWarningModalLessThan5" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <form>
              <Dialog.Title className="px-6 mb-2 text-black text-xl font-medium">
                The category has fewer than five items
              </Dialog.Title>
              <div className="px-6 mb-5">
                <p>
                  Would you like to proceed the publication of the category?
                </p>
                {modalContent.map((element: any) => {
                  return <p className="mt-3">- {element?.categoryName}</p>;
                })}
              </div>
              <hr className="my-5" />
              <div className="px-6">
                <div className="w-100 flex justify-between items-center mt-4">
                  <Button
                    type="button"
                    variant="normal"
                    onClick={() => handleCloseModalWarning()}
                  >
                    <p>Cancel</p>
                  </Button>
                  <Button
                    variant="add"
                    type="button"
                    onClick={handleConfirmPublish}
                  >
                    <p>Publish</p>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
      {modal.type === "updateCategoryName" && (
        <Modal>
          <div className="flex flex-col bg-white text-black rounded w-96 py-4">
            <Dialog.Overlay />
            <form onSubmit={handleUpdateCategoryName}>
              <Dialog.Title className="px-6 mb-2 text-black text-xl font-medium">
                Update Category Name
              </Dialog.Title>
              <div className="px-6 mt-4">
                <Input
                  label="Category Name"
                  type="text"
                  required={true}
                  placeholder="Input the new category name"
                  value={newCategoryName}
                  onChange={(e) => setNewCategoryName(e.target.value)}
                />
              </div>
              <div className="px-6 mt-8">
                <div className="w-100 flex justify-between items-center mt-4">
                  <Button
                    type="button"
                    variant="normal"
                    onClick={() => handleCloseModalWarning()}
                  >
                    <p>Cancel</p>
                  </Button>
                  <Button variant="add" type="submit">
                    <p>Confirm</p>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </section>
  );
}

export default CategoriesDetail;
