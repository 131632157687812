import {useMemo} from "react";

// Components
import Form from "components/Table";
import Tabs from "components/Common/Tabs";
import Title from "components/Common/Title";

// Tables
import ActualMerchants from "./Tabs/ActualMerchants";
import ArchivedMerchants from "./Tabs/ArchivedMerchants";

function Merchants() {
  const tabs = useMemo(
    () => (
      <Tabs
        tabsName={["Approved", "New", "Unverified", "Archived"]}
        primaryTabs
      >
        <ActualMerchants status="PUBLISHED" />
        <ActualMerchants status="NEW" />
        <ActualMerchants status="UNVERIFIED" />
        <ArchivedMerchants />
      </Tabs>
    ),
    []
  );

  return (
    <section>
      <Title>Merchants</Title>
      <Form>
        <div className="mt-3">{tabs}</div>
      </Form>
    </section>
  );
}

export default Merchants;
