// @ts-nocheck
import {createApi} from "@reduxjs/toolkit/query/react";
import {store} from "store/store";
import customFetchBase from "./customFetchBase";

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: customFetchBase,
  tagTypes: ["Notifications", "NotificationsCount"],
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (params) => ({
        url: `/notification/admin/notification${!params ? "/" : `${params}`}`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["Notifications"],
    }),
    getNotificationCount: builder.query<
      any,
      {aps: {alert: string; badge: number}}
    >({
      query: () => ({
        url: `/notification/admin/notification/badge-count`,
        method: "GET",
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      providesTags: ["NotificationsCount"],
    }),
    readNotificationByID: builder.mutation<any, any>({
      query: ({id}) => ({
        url: `/notification/admin/notification/${id}/mark-as-read`,
        method: "POST",
        body: {},
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
      invalidatesTags: ["Notifications", "NotificationsCount"],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetNotificationCountQuery,
  useReadNotificationByIDMutation,
} = notificationApi;
