import Tabs from "components/Common/Tabs";
import Title from "components/Common/Title";
import Activity from "./Tabs/Activity";
import Unread from "./Tabs/Unread";
import Read from "./Tabs/Read";

function Notification() {
  return (
    <div className="flex flex-col gap-4">
      <Title classes="flex items-center mb-0">Notifications</Title>
      <p className="text-xs text-gray-500">
        Activity: <b>{25}</b> / Unread: <b>{5}</b>/ Read: <b>{10}</b>
      </p>
      <Tabs tabsName={["Activity", "Unread", "Read"]} primaryTabs>
        <Activity />
        <Unread />
        <Read />
      </Tabs>
    </div>
  );
}

export default Notification;
